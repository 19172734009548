@import '../SCSS/cssVariable.scss';


.inputs {
    height: 40px;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
}

.inputs input,
.inputs select, .input textarea, .inputs .form-control {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 2px solid $primary-color;
    outline: none;
    font-size: $mainFntSize;
    transition: 0.3s;
    background-color: transparent;
    color: #707070;
}

.inputs input:focus,
.inputs select:focus, .input textarea:focus, .inputs .form-control:focus {
    border-color: $primary-color;
}

.inputs label {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    font-size: $commonFntSize;
    transition: 0.5s;
    background-color: transparent;
    width: 80%;
}

.inputs input:focus~label,
.inputs input:valid~label, .inputs DatePicker:focus~label, .inputs DatePicker:valid~label {
    transform: translateY(-23px);
    color: $primary-color;
    font-size: $smallFntSize;
    display: inline;
}

.inputs select:focus~label,
.inputs select:valid~label {
    transform: translateY(-23px);
    color: $primary-color;
    font-size: $smallFntSize;
    display: block;
}
.inputs textarea:focus~label,
.inputs textarea:valid~label {
    transform: translateY(-23px);
    color: $primary-color;
    font-size: $smallFntSize;
    display: block;
}
