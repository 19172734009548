@import '../SCSS/cssVariable.scss';

.singleNotify {
    width: 60%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba($color: #000000, $alpha: 0.4);
    .imgIcon {
        width: 50px;
        height: 50px;
        background-color: gray;
        border-radius: 25px;
        margin-right: 20px;
        overflow: hidden;
    }

    .subContent {
        color: rgba($color: $logo1-color, $alpha: 0.6);
    }
    .actionTime{
        color: rgba($color: $logo1-color, $alpha: 0.4);
    }

    .postImg {
        width: 75px;
        height: 75px;
        background-color: lightblue;
        border-radius: 5px;
        overflow: hidden;

    }
    .user-profile{
        min-width: 35px;
        height: 35px;
        border-radius: 100px;
        // border: 1px solid #c7c7c7;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: orange;
        margin-right: 10px;
       .initial-text{
        font-size: 14px;
       }
    }
}
.notification-page-wrap{
    .header-right{
        gap: 10px;
        align-items: center;
    }
    .noti-select-box{
        select{
            font-size: 13px;
            height: 32px;
        }
    }
}

