@import '../SCSS/cssVariable.scss';

  .dropdown input {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown ul li {
    padding: 5px;
  }
  
  .dropdown ul li:hover {
    background-color: #f1f1f1;
  }
.selectdropdown{
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid #CCABCD;
  height: 58px;
  background-color: #fff;
  color: $primary-color;
  border-radius: 6px;
  padding: 10px;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  .searchable-dropdown{
    width: 100%;
    border: none;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus, &:focus-visible{
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

    .dropdown {
      position: absolute;
      background-color: #fff;
      padding: 10px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      z-index: 9;
      border-radius: 10px;
      width: 100%;
      top: 50px;
      ul{
        max-height: 165px;
        overflow: auto;
      }
      li{
        font-size: 14px;
      }
      .search-box{
        border: 2px solid #CCABCD;
        border-radius: 6px;
        padding: 10px;
      }
    }
    .select-label{
      font-size: 12px;
      font-family: $commonfntFmly;
      width: 100%;
    }
}  