@import '../SCSS/cssVariable.scss';

@mixin actDesign() {
    display: block;
    position: absolute;
    height: 10px;
    width: 50%;
    right: 0;
    background-color: $white-color;
}

@mixin acttbfrDesign() {
    content: '';
    position: absolute;
    right: 10px;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    transition: 0.5s ease-in;
}

.navigation {
    background-color: $primary-color;
    height: 100%;
    position: relative;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
.hubspotlink{
    display: flex;
    padding:15px 10px;
    align-items: center;
    color: #fff;
    cursor: pointer;
    &:hover{
        color: $logo2-color;
    }
    .title{
        align-items: center;
        gap: 4px;
    }
}
.hubspot-list{
    height: auto;
    li{
        margin-left: 12px;
        border-left: 1px solid #8f8f8f;
    }
}
    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 90vh;

        li {
           
            a {
                position: relative;
                color: $white-color;
                text-decoration: none;
                font-size: $mainFntSize;
                padding: 12px 10px;
                width: 100%;
                display: flex;
                align-items: center;
                transition: 0.5ms ease-in;
                font-size: 14px;

                b:nth-child(1) {
                    display: none;
                }
            }

            a:hover {
                color: $logo2-color;
            }

            .active {
                color: $logo2-color;
                background-color: $white-color;
                margin-left: 10px;
                border-radius: 50px 0px 0px 50px;
                transition: border-radius 0.1s ease-in;

                b:nth-child(1) {
                    @include actDesign();
                    top: -10px;
                }

                b:nth-child(1)::before {
                    @include acttbfrDesign();
                    top: 0;
                    border-bottom-right-radius: 50px;
                }

                b:nth-child(2) {
                    @include actDesign();
                    bottom: -10px;

                }

                b:nth-child(2)::before {
                    @include acttbfrDesign();
                    bottom: 0;
                    border-top-right-radius: 50px;
                }
            }

            .icon {
                font-size: 1.5em;
                padding-right: 10px;
                display: flex;
            }
        }
    }
}

.smallNav {
    width: 50px;

    .list .title {
        display: none;
    }

}

.bigNav {
    width: 204px;

    .list .title {
        display: flex;
    }
}

.navigation::-webkit-scrollbar {
    background-color: $lightLogo1-color;
    width: 7px;
    border-radius: 30px;

}

.navigation::-webkit-scrollbar-thumb {
    background-color: $lightLogo1-color;
    border-radius: 30px;
}

*::-webkit-scrollbar:hover {
    width: 12px;
    transition: width 0.5s ease-in;

}

*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

@media only screen and (max-width: 768px) {
    .navigation{
        position:absolute;
        z-index: 97;
        
    }.bigNav {
        width: 170px;
        margin-left: -200px;
        transition-property: margin;
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;

        .list .title {
            display: inline-block;
        }

    }
    .smallNav{
        width: 170px;
        margin-left: 0px;
        transition-property: margin;
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;
        .list .title {
            display: inline-block;
        }
    }

}
@media screen and (max-width:768px){
    .navigation ul li {
        a{
            width: 94%;
        }
        .active b:nth-child(2)::before,.active b:nth-child(1)::before{
            right: 0px;
        }
    }
}