@import "../SCSS/cssVariable.scss";

.forum-container {
  margin-top: 60px !important;
}
.forumcontainer {
  background-color: #f8f8f8 ;
  &::-webkit-scrollbar {
    background-color: $lightLogo1-color;
    width: 10px;
    border-radius: 30px;
  }
  .pagination-wrap{
      background-color: #fff;
      border-top: 1px solid $primary-color;
  }
  .new-post {
    .add-comment-editor{
      width: 93%!important;
      textarea{
        padding: 10px;
      }
    }
  }

}
.add-post-editor {
  textarea{
    border: none;
    padding:0 10px;
    &:focus{
      outline: none;
    }
    &::placeholder{
      color: #999;
    }
  }
 
}
.add-post-editor__suggestions, .add-comment-editor__suggestions{
  max-height: 300px;
  overflow: auto;
  box-shadow: 0px 1px 14px 0px rgb(125, 125, 125);
  border-radius: 6px;
  ul{
    padding: 10px!important;
    li{
      margin-bottom: 3px!important;
      span{
      font-size: 13px!important;
    }

    }
  }
}
.add-comment-editor{
  .add-comment-editor__highlighter{
    min-height: 75px;
  }
  textarea{
    padding: 10px 55px 10px 10px;
    border: 1px solid #d4d4d4;
    border-radius: 6px;
    font-size: 13px!important;
  }
}

*::-webkit-scrollbar{
  background-color:$lightLogo1-color;
  width: 10px;
  height: 5px;
  border-radius: 30px;
  
}

*::-webkit-scrollbar-thumb {
  background-color:$lightLogo1-color;
  border-radius: 30px;
}
*::-webkit-scrollbar:hover {
  width: 10px;
  transition: width 0.5s ease-in;
  
}
*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}
.forumcontainer {
  display: block;
  grid-template-columns: 70% auto;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  gap: 15px;
  flex-wrap: wrap;
  padding: 0 15px;

  overflow: auto;
  // flex-direction: column;

  .addpost-section {
   
    .ant-picker{
      border: 2px solid #CCABCD;
      border-radius: 6px;
      &:focus{
          outline: none;
          border: 2px solid $primary-color;
          box-shadow: none;
      }
      input{
          border: none;
          &:focus{
              border: none;
          }
      }
     
  }
  select{
      height: auto;
      font-size: 14px;
      border: 2px solid #CCABCD!important;
      border-radius: 4px;
  }
  }

  .addpost-section::-webkit-scrollbar-thumb {
    background-color: $lightLogo1-color;
    border-radius: 30px;
  }

 
  .addpost-section {
    // height: 100%;
    // overflow: auto;
    width: 69%;

    .card {
      @include borderStyle();
      border-radius: 8px;
      padding:8px 6px;
      cursor: pointer;
    }
    .addpost-section-inner{
      overflow: auto;
      height: calc(100vh - 464px);
      
      .dropdown-menu {
        svg{
          fill: #000;
        }
      }
    }
  }

  .text-image {
    padding: 10px;
    p {
      margin-bottom: 8px;
      font-family: $commonfntFmly;
      font-size: 15px;
    }
  .post-description{
    font-family: $RFntFmly;
    font-size: 13px;
  }
    .hot-post-image {
      position: relative;
      width: 100%;
      padding-top: 45%; /* 1:1 Aspect Ratio */
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .right-section {
    // height: 100%;
    overflow: auto;
    flex: 29%;
     .pin-icon{
      width: 26px;
      height: 26px
     }
     .recent-comment-wrap{
      .card-inner{
        height: calc(100vh - 137px);
        overflow: auto;
        .post-header{
          justify-content:flex-start; 
          .user-info{
            .user-name,.post-time {
              color: #fff
            }
            .post-time{
              font-size: 12px
            }
          }
        }
        .post-description{
          padding: 5px 10px
        }
      }
     }
    .hot-post-wrap {
      @include borderStyle();
      margin-bottom: 10px;
      border-radius: 8px;
      background-color: #fff;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.24);
        box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.24);
        transition: ease-in-out 0.2s;
        border-color: $primary-color;
      }
    }
    .user-comments {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      padding: 10px;
      border-bottom: 1px solid #b9b9b9;

      &:last-child {
        margin-bottom: 0;
      }
      .image-content {
        width: 100%;
      }
      .text-image {
        padding: 10px;
        p {
          margin: 0;
          font-family: $commonfntFmly;
        }

        img {
          width: auto;
          height: 77px;
          border-radius: 10px;
        }
      }

      p {
        font-family: $RFntFmly;
        width: 100%;
      }
    }

    .card {
      padding: 10px;
      height: auto;
      border-radius: 8px;
      background: #fff;
      h5{
        font-size: 18px;
      }
      .gallery {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

        .media-post {
          img {
            height: 100px;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
      }

      .user-text-comment {
        color: #999;
        font-style: italic;
        font-family: $RFntFmly;
      }

      font-family: $RFntFmly;

      .user-profile {
        background-color: #5cb85c;
      }
    }
  }

.quill-editor-custom-wrapper{
      width: 95%;
      .ql-toolbar{
        border-radius: 8px 8px 0 0;
      }
      .ql-container  {
        border-radius:0 0 8px 8px;
        height: 115px!important;
        margin-bottom: 10px;
    
        }
    }
    // .new-comments{
    //   border-top: 2px solid #dfdfdf;
    // }
}

.post {
  background: #fff;
  @include borderStyle();
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
}

.post-header {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-info {
  flex-grow: 1;
  font-size: 14px;
  .category {
    font-size: 12px;
  }
  .post-time-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    .user-name{
      color: #fff!important;
    }
  }
}

.user-name {
  margin-bottom: 5px;
  font-size: 14px;
  color: #ffffff;
}

.post-time {
  color: #888;
  font-size: 13px;
}
.file-doc-wrap{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
  width: 100%;
  .file-doc{
      width: 48%;
      display: flex;
      gap: 6px;
      align-items: center;
      border: 1px solid #bdbdbd;
      margin-top: 5px;
      padding: 8px 5px;
      border-radius: 6px;
      background-color: #fff;
      a{
        color: $primary-color;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
        font-size: 12px;
      }
      svg{
        width: 24px;
        height: 24px;
        fill: $primary-color;
      }
      img{
        width: 24px;
        height: 24px;
      }
  }
}
.post-content {
  margin-top: 10px;
  font-size: 14px;
  padding: 0 10px;

  .post-title{
    font-size: 15px;
    font-family: $commonfntFmly;
    word-break: break-all;
  }

  .post-description{
    line-height: 1.6;
    padding:5px 10px;
  }

  .carousel{
    video{
      width: 100%;
    }
  }
  .edit-text {
    font-size: 14px;
    color: #aeaeae;
    font-family: $RFntFmly;
  }
  p {
    font-family: $RFntFmly;
    margin-bottom: 0;
    font-size: 13px;
    word-wrap: break-word;
  }
}

.post-actions {
  margin-top: 10px;
  padding: 8px 10px;
  background-color: #fff;
  border-top:1px solid #b9b9b9;
  border-radius: 0 0 6px 6px;
  .post-action-btn {
    display: flex;
    align-items: flex-start;
  }
  .like-btn-wrap {
    display: flex;
    // align-items: center;
    flex-direction: column;
    .like-count {
      // padding-left: 17px;
      color: #a563a6;
      font-weight: normal;
      font-size: 12px;
    }
  }
  button {
    display: inline-flex;
    background-color: transparent;
    align-items: center;

    span {
      color: #696969;
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
      width: 18px;
      height: 18px;
      fill: #696969;
      g {
        fill: #696969;
      }
    }

    &:hover {
      svg,
      span {
        color: $primary-color;
        fill: $primary-color;
      }
    }
  }
  .like-btn {
    &:hover {
      svg {
        g {
          fill: $primary-color;
        }
      }
    }
  }
  .liked {
    svg {
      g {
        fill: $primary-color;
      }
    }

    span {
      color: $primary-color;
    }
  }
}

.like-btn,
.comment-btn,
.share-btn {
  border: none;
  background: #ffffff;
  padding: 5px 10px 0 0;
  margin-right: 10px;
  cursor: pointer;
}

.content {
  max-width: 500px;
  margin: auto;
}

.post-pic {
  width: 100%;
  height: 240px;
  margin-right: 5px;
  object-fit: contain;
  display: block;
}



/* The Modal (background) */
.modal {
  /* Hidden by default */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: auto;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  margin-top: 10px;
  padding-top: 10px;
  /*  background-color: rgb(0,0,0);  Fallback color 
    background-color: rgba(0,0,0,0.4);  Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  width: auto;
  /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  /*margin: 10% auto;*/
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.hidden {
  display: none;
}

.show-all-comments {
  color: $primary-color;
  font-weight: bold;
  font-size: 14px;
}

.comment-section {
  font-family: Arial, sans-serif;
}

.comment-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-details {
  flex-grow: 1;
  width: 90%;
  .comment-editor{
    position: relative;
    input{
      font-size: 12px;
      padding-right: 55px;
      &:focus{
        box-shadow: none;
        border: 1px solid $primary-color;
      }
    }
    .comment-btn-wrap{
      position: absolute;
      top: 5px;
      right: 0px;
      display: flex;
      gap: 10px;
      align-items: center;
      .action-btn{
        cursor: pointer;
        &:hover{
          svg{
            fill: $logo2-color;
          }
        }
      }
      .close-btn{
        svg{
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .userpost-time {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .like-btn-wrap {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 6px;
    .like-btn {
      padding: 0;
      background-color: transparent;
      font-size: 13px;
      font-family: $RFntFmly;
      color: blue;
    }
  }
  .commnet-bg {
    background-color: $white-color;
    padding: 6px 12px;
    border-radius: 6px;
    border: 1px solid #d1d1d1;
    display: inline-block;
    width: 100%;
    .userpost-time{
      gap: 5px!important;
    }
    .post-time{
      color: #888!important;
    }
  }
}

.user-name {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  color:#7d1d7e;
}

.comments {
  margin: 10px 0 0 0;
  padding: 10px 0 0 5px;
  // background-color: white;
  border-top: 1px solid #b7b7b7;
}

.child-comment-section {
  display: flex;
  margin: 10px;
  padding: 10px;
}

.comment-buttons {
  color: darkblue;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

.comment-buttons > span {
  padding: 10px;
  font-family: $RFntFmly;
  cursor: pointer;
}

.comment-content {
  width: auto;
  border-radius: 6px;
  font-size: 13px;
  margin: 0;
  font-family: $RFntFmly;
  display: inline-block;
  word-break: break-all;
  p{
    margin-bottom: 0;
  }
}
.post-header {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b9b9b9;
  background: #a563a6;
  padding: 5px 10px;
  color: white;
  border-radius: 6px 6px 0 0;
 .pin-post{
  cursor: pointer;
 }
 .pin-icon{
  fill: #fff;
}
  .more-option {
    button {
      &:hover {
        svg {
          fill: $primary-color;
        }
      }
    }
    a {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      
    }
    .dropdown-toggle {
      background-color: transparent;

      padding: 0;
      border: none;
      &::after {
        display: none;
      }
    }
  }
  .user-profile-wrap {
    display: flex;
    gap: 15px;
    align-items: flex-start;
  }

  svg {
    height: 17px;
    width: 17px;
    fill:#fff;
  }
}
.forumcontainer {
  .attachment-width{
    margin-top: 10px;
    .upload-btn{
      width: 140px;
      svg{
        &:hover{
          fill: #fff;
        }
      }
    }
  }
  .upload-btn {
    background-color: $primary-color;
    padding: 6px 10px;
    color: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    @include borderStyle();
    cursor: pointer;
    &:hover {
      background-color: $logo2-color;
      
    }
  }
  .post {
    padding: 0;
    .post-time {
      font-family: $RFntFmly;
      margin: 0;
      color: #fff;
      font-size: 12px;
    }

    .user-name {
      color: #7d1d7e;
      font-weight: 700;
    }
  }

  .user-profile {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    // border: 1px solid #c7c7c7;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: orange;

    span {
      font-size: 14px;
    }
  }

  .comment {
    display: flex;
    margin-bottom: 20px;
    gap: 10px;

    .user-profile {
      background-color: rgb(132 159 23);
      width: 30px;
      height: 30px;
      span{
        font-size: 12px;
      }
    }
  }

  .new-post-wrap {
    .text-danger{
      display: flex;
      align-items: center;
      width: 150px;
    }
    .quill-editor-custom-wrapper{
      width: 100%;
    }
    .editor-footer {
      border-top: 1px solid #d2d2d2;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      .text-danger{
        display: flex;
        align-items: center;
      }
      .category-select-box {
        select {
          background-color: #fff;
          border: none;
          border-radius: 6px;
          font-size: 14px;
          // @include borderStyle();
          cursor: pointer;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .post-btn {
        background-color: $primary-color;
        padding: 10px 30px;
        color: #fff;
        border: none;
        border-radius: 100px;
      }
    }

    .upload-doc {
      display: flex;
      gap: 10px;

    

      .ant-upload {
        button {
          background-color: #efefef;
          border: none;
          &:hover {
            background-color: #ffdfff;
            color: $primary-color;
          }
        }
      }
      .edit-btn {
        background-color: #fff;
        padding: 6px 6px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        @include borderStyle();
        &:hover {
          color: $primary-color;
          border-color: $primary-color;
          svg {
            fill: $primary-color;
          }
        }

        svg {
          width: 30px;
          height: 18px;
          cursor: pointer;
        }
      }
    }
  }
.edit-post-container{
  .quill-editor-custom-wrapper{
    width: 100%;
    .ql-toolbar{
      border-radius: 0;
      border-top: none;
      border-left: 0;
      border-right: 0;
    }
    .ql-container {
      margin-bottom: 0;
      border: none;
    }
  }
  .file-edit-wrap{
    display: flex;
    gap: 8px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .edit-input-wrap{
    @include borderStyle();
    overflow: hidden;
    input,textarea{
      border: none;
      border-radius: 0;
      color: #000;
      &::placeholder{
        color: #999;
      }
      &:focus{
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
    textarea{
      font-size: 13px;
    }
    input{
      font-family: $commonfntFmly;
      border-bottom: 1px solid #b9b9b9;

    }
  }
  .button{
    padding: 7px 24px;
  }
  a{
    cursor: pointer;
  }
  .edit-post-wap{
    border: 1px solid #999;
    padding: 10px;
    width: 250px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    span{
      word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    font-size: 12px;
    }
  }
  img{
    width: 180px;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 20px;
  }
  // span{
  //   margin-right: 20px;
  // }
  svg{
    width: 35px;
    height: 20px;
    cursor: pointer;
    &:hover{
      fill: $primary-color;
    }
  }

  .edit-post-button{
    svg{
      width: 24px;
      height: 24px;
    }
  }
}
  .new-post {
    display: flex;
    gap: 10px;
    width: 100%;
    .user-width{
      width: 40px;
    }
    .upload-doc {
      border-top: 1px solid #999;
    }
.title-input-style{
  font-size: 16px;
  font-family: $commonfntFmly;
  &::placeholder{
    font-size: 16px;
  }
}
    .input-style {
      border: none;

      &:hover,
      &:focus,
      &:focus-visible {
        border: none;
        outline: none;
      }
    }

    .text-editor {
      display: flex;
      gap: 15px;
      align-items: flex-start;
      width: 95%;
      span{
        font-size: 16px;
      }
      .send-btn {
        background: $primary-color;
        padding: 10px 10px;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          background-color: $logo2-color;
        }

        svg {
          fill: #fff;
          width: 30px;
          height: 20px;
        }
      }
    }
    .text-editor-wrap{
      width: 100%;
     .ql-editor{
      p{
        word-break: break-all;
      }
     }
    }
  }
  .error-text-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .textarea-style{
    font-size: 14px;
    font-family: $RFntFmly;
    padding-top: 0;
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
  .text-editor {
    width: 100%;

    input {
      width: 100%;
      height: 100%;
      font-family: $RFntFmly;
      font-size: 13px;
      &::placeholder {
        font-family: $RFntFmly;
        font-size: 14px;
        color: #aeaeae;
      }

      &:focus {
        border: 1px solid $primary-color;
        box-shadow: none;
      }
    }
  }
}

.bg-color {
  background-color: #e7edee;
}

.user_name {
  font-size: 16px;
}
.filter-wrap {
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 1px 10px 8px -7px rgb(170 170 170 / 33%);
    -moz-box-shadow: 1px 10px 8px -7px rgb(170 170 170 / 33%);
    box-shadow: 1px 10px 8px -7px rgb(170 170 170 / 33%);
    padding-bottom: 10px;
      .form-check{
        input[type='checkbox']{
          width: 16px;
        }
      }
  .btn-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .filter-reset-btn{
      border: none;
      color: $logo2-color;
      background-color: #fff;
    }
  }
  .ant-picker-active-bar {
    background:$primary-color;
  }
  .ant-picker-focused.ant-picker {
    border-color: $primary-color;
  }
  .ant-picker {
    &:hover,
    &:active,
    &:focus {
      border-color: $primary-color;
    }
  }
  .dropdown-menu {
    min-width: 200px;
   form{
    max-height: 250px;
    overflow: auto;
   
   }
    .form-check {
      margin-bottom: 8px;
    }
  }
  .ant-picker {
    width: 270px;
    padding: 6px 12px;
    @include borderStyle();
    .anticon-calendar {
      svg {
        fill: #595c5f;
      }
    }
    .ant-picker-input {
      input {
        font-size: 14px;
        &::placeholder {
          color: #595c5f;
        }
      }
    }
  }
  .btn.show,.filter-btn {
    background-color: #fff !important;
    border: 2px solid #CCABCD;
    color: #000 !important;
    &::after {
      display: none;
    }
    svg {
      width: 12px;
      height: 12px;
      margin-left: 10px;
      fill: #afafaf;
    }
    &:hover,
    &:active,
    &:focus {
      // background-color: #ffdfff!important;
      color: $primary-color !important;
      border-color:$primary-color;
      svg {
        fill: $primary-color;
      }
    }
  }
  button {
    font-size: 14px;
  }
  .form-check {
    label {
      font-size: 13px;
    }
  }
  .btn-wrap {
    text-align: right;
    padding-top: 10px;
    .filter-ok-btn {
      background-color: $primary-color;
      color: $white-color;
      padding: 3px 10px;
      border-radius: 3px;
      border: none;
      font-size: 14px;
      &:hover {
        background-color: $logo2-color;
      }
    }
  }
}

.not-found-wrap {
  margin-top: 120px;
  text-align: center;
  h4 {
    padding-top: 15px;
    font-size: 22px;
  }
}
//Upload
// .custom-file-input {
//   display: inline-block;
//   position: relative;
//   font-family: Arial, sans-serif;
//   font-size: 16px;
//   color: #333;
//   cursor: pointer;
//   padding: 10px 20px;
//   border: 2px solid #3498db;
//   border-radius: 4px;
//   background-color: #3498db;
//   color: #fff;
//   transition: background-color 0.3s;
// }

.custom-file-input:hover {
  background-color: #2980b9;
}
.post-upload-image-wrap{
  display: flex;
 gap: 10px;
  margin-top: 10px;
  .post-upload-image {
    width: 130px;
    border: 1px solid #999;
    border-radius: 6px;
    height: 80px;
    padding: 8px 10px;
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    object-fit: contain;
    img{
      width: 80px;
    }
  }
  svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    &:hover{
      fill: $logo2-color;
    }
  }
}


.custom-file-input input {
  position: absolute;
  top: 0;
  left: 0;
  // width: 100%;
  // height: 100%;
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Optional: Style the label to look like a button */
// .custom-file-input label {
//   display: inline-block;
//   padding: 10px 20px;
//   font-size: 16px;
//   font-weight: bold;
//   color: #fff;
//   background-color: #3498db;
//   border: 2px solid #3498db;
//   border-radius: 4px;
//   cursor: pointer;
//   transition: background-color 0.3s;
// }

.custom-file-input label:hover {
  background-color: #2980b9;
}


.ql-editor{
  img{
    width: 300px;
    height:auto;
    object-fit: contain;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }
}
.ql-mention-list-container{
  background: white;
    z-index: 7;
    box-shadow: -1px 6px 17px -7px rgb(173, 173, 173);
}

.add-pass-modal{
  .collabhub-popup{
    .quill-editor-custom-wrapper{
      background-color: $white-color;
      border-radius: 10px;
      border: 1px solid #ccc;
    }
    .ql-toolbar.ql-snow{
      border-top:0;
      border-left: 0;
      border-right: 0;
    }
    .ql-container.ql-snow{
      border: none;
    }
    .ant-btn {
      display: none
    }
  }
}
.category-container{
  margin-bottom: 15px;
  width: 280px;
}
.quill-editor-custom-wrapper .ql-tooltip{
  left: 12px!important;
}
@media screen and (max-width: 1200px) {
  .forumcontainer {
    display: flex;
    .addpost-section{
      width: 100%;
      
    }
  }
}
@media screen and (min-width: 1200px) {
  .forumcontainer {
    display: flex;
  }
}
@media screen and (max-width:1024px){
  .forum-container{
    .addpost-section .addpost-section-inner{
      height: calc(100vh - 100px);
    }
  }
}
@media screen and (max-width: 480px) {
  .filter-wrap {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
    .dropdown{
      flex: 1;
      button{
        width: 100%;
      }
    }
    .ant-picker{
      flex: 100%;
    }
  }
  .forumcontainer{
    margin: 0 12px;
    .new-post-wrap .editor-footer{
    .post-btn-wrap{
      width: 100%;
      button{
        width: 100%;
      }
    }
  }
 }
 .forumcontainer{
  padding:0 10px 60px 10px;
  margin: 0;
  .addpost-section {
    .addpost-section-inner{
      height: calc(100vh - 195px);
    }
  }
  .pagination-wrap{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0 10px;
    left: 0;
  }
}
}
@media screen and (max-width:768px){
  .forum-container{
  .user-info {
    flex: 1;
    .post-time-wrap{
      flex-wrap: wrap;
      gap: 0;
      .post-time{
        width: 100%;
      }
    }
  }
  }
.addpost-section-inner{
  .text-end{
    flex-wrap: wrap;
    p{
      width: 100%;
      text-align: center;
    }
    .ant-pagination {
      width: 100%;
      text-align: center;
    }
  }
}
.post-content .file-doc-wrap{
  .file-doc{
    flex: 1;
    width: auto;
    a{
      width: 210px;
    }
  }
}
}
@media screen and (max-width:480px){
  .forumcontainer{
    padding:0 10px 60px 10px;
    margin: 0;
    .addpost-section {
      .addpost-section-inner{
        height: calc(100vh - 195px);
      }
    }
    .new-post .text-editor{
      flex-wrap: wrap;
      gap: 2px;
    }
    }
  }
@media screen and (max-width :430px ) {
  .file-doc-wrap {
    gap: 2px;
    .file-doc{
      width: 100%;
    }
  }
}
