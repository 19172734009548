@import '../SCSS/cssVariable.scss';

.form-wrap {
    display: flex;
    flex-direction: column;
    gap: 14px;

    h5 {
        font-size: 16px;
        margin-bottom: 0px;
        font-family: $semiBold;
    }

    .ant-space-item {
        width: 100%;
    }
}

.cclBtn {
    background-color: #F6EFF6;
    color: #2c0036;
}

.offcanvas {
    width: 30% !important;

    .offcanvas-header {
        background-color: $logo1-color;

        .btn-close {
            opacity: 1;
        }

        .offcanvas-title {
            color: #fff;
            font-size: 16px;
        }
    }
}

.inputSelectWrap {
    position: relative;

    .down-arrow {
        position: absolute;
        right: 12px;
        top: 23px;
    }
}

.selectdropdown {
    .down-arrow {
        position: absolute;
        right: 12px;
    }
}

.action-btn-wrap {
    display: flex;
    gap: 10px;

    .action-btn {
        display: flex;
        gap: 20px;
        align-items: center;
    }

    p {
        margin: 0;
        font-size: 14px;
        display: flex;
        gap: 5px;
        align-items: center;

    }

    .action {
        cursor: pointer;

        &:hover {
            color: $logo2-color;
        }
    }
}

.preview-btn {
    margin-left: 5px;
    border: none;
    padding: 1px 4px;
    background-color:$primary-color;
    border-radius: 4px;
    display: none;
    font-size: 10px;
    color: #fff;
    &:hover{
        background-color: $logo2-color;
    }
}

.hubspot-table-page {
    .ant-table-body {
        height: calc(-200px + 100vh);
    }
}

.hover-preview {
    span {
        display: flex;
    }
}

.hover-preview {
    .redirect-cusor {
        width: 140px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
    }

    &:hover {
        .preview-btn {
            display: inline-block;
        }

        .redirect-cusor {
            width: 140px;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
        }
    }
}

.preview-btn-hover {
    svg {
        &:hover {
            fill: green;
            cursor: pointer;
        }
    }
}

.hs-contact-page {
    .ant-table-footer {
        bottom: 0;
        right: 0;
    }
}

.personal-info-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;

    .initial-text {
        background: #afafaf;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 24px;
        color: #fff;
        margin: 0;
    }

    .personal-info {
        h6 {
            font-size: 16px;
            margin-bottom: 0;
            word-break: break-all;
        }

        p {
            margin: 0;
            font-size: 13px;
            color: #565656;
        }
    }

    svg {
        // fill: $logo1-color;
        cursor: pointer;
        color: $logo1-color;
    }
}

.main-btn-wrap {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #E5E5E5;
    margin-bottom: 15px;

    .button-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        p {
            margin-bottom: 5px;
            font-size: 12px;
        }

        .icon {
            width: 33px;
            height: 33px;
            background-color: #F6EFF6;
            border: 1px solid;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .edit-btn {
        .icon {
            border: 1px solid $logo1-color;

            svg {
                fill: $logo1-color;
            }
        }
    }

    .delete-btn {
        .icon {
            border: 1px solid #FF5454;

            svg {
                fill: #FF5454;
            }
        }
    }
}

.height-box {
    height: 35px;
    overflow: hidden;
    padding: 4px 24px;
    mask-image: linear-gradient(#ffffff, #ffffff, rgba(255, 255, 255, 0));

    p {
        margin: 0;
    }
}

.note-editor {
    .quill-editor-custom-wrapper {
        background-color: #fff;
    }
}

.accordion-wrap {
    height: 80vh;
    overflow-y: auto;

    .accordion-content {
        height: auto;
        font-size: 14px;
        padding: 4px 5px 10px 24px;

        p {
            margin: 0;
        }

        // .edit-note-wrapper{
        //     .ql-editor{
        //         white-space: nowrap;
        //     }
        // }
        p {
            margin-bottom: 0;
        }
    }

    .accordion-item {
        margin-bottom: 15px;
        box-shadow: 0px 0px 4px 2px rgb(0 0 0 / 10%);
        background-color: #fff;

        .action-wrap {
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
                fill: red;
                width: 16px;
                height: 16px;
                cursor: pointer;
            }
        }

        .accordion-title {
            padding: 8px 8px 0 8px;

            .comment-details {
                display: flex;
                gap: 6px;
                margin-bottom: 8px;
                width: 100%;

                .editor {
                    width: 100%;
                }

                .comment {
                    font-family: $RFntFmly;
                    font-size: 12px;
                }

                p {
                    font-size: 14px;
                }

                .profile-name {
                    font-family: $semiBold;
                }

                .profile-icon {
                    svg {
                        width: 30px;
                        height: 30px;
                        fill: #c2c2c2;
                    }
                }
            }

            .title-header-wrap {
                display: flex;
                justify-content: space-between;

                .title-header {
                    display: flex;
                    gap: 10px;
                    cursor: pointer;

                    &:hover {
                        .noteby {
                            color: $logo1-color;
                        }
                    }

                    svg {
                        stroke: $logo1-color;
                        cursor: pointer;
                    }

                    p {
                        display: flex;
                        gap: 4px;
                    }
                }

                p {
                    margin-bottom: 0;
                }

                .note-date {
                    font-size: 12px;
                    color: #b9b9b9;
                }
            }

            .comment-link {
                color: $logo1-color;
                font-family: $semiBold;
                margin-top: 8px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }

                svg {
                    fill: #999;
                    margin-right: 5px;
                }
            }
        }
    }
}

.bold-style {
    font-family: $semiBold;
}

.note-header-wrap {
    h5 {
        border-bottom: 1px solid #dcdcdc;
        padding-bottom: 5px;
    }
}

.note-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-family: $semiBold;
    }
}

.column {
    display: flex;
    gap: 10px;

    .note-overview-col {
        flex: 65%;
        padding: 15px 5px 15px 15px;
        border-left: 1px solid #d2d2d2cc;

    }

    .association-overview-col {
        flex: 40%;
        padding: 15px 15px 15px 15px;
        border-left: 1px solid #d2d2d2cc;
        overflow: auto;
        height: 92vh;
        .association-wrapper{
            padding-bottom: 10px;
            padding-top: 10px;
            &:first-child{
                padding-top: 0;
            }
        }
    }

    .note-month {
        margin-bottom: 5px;
    }
}

.personal-info-col {
    flex: 40%;
    background-color: #fff;
    padding: 15px;
    height: 92vh;
    overflow: auto;

    .back-arrow {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-bottom: 10px;
        cursor: pointer;

        &:hover {
            svg {
                path {
                    stroke: $logo2-color;
                }
            }

            a {
                color: $logo2-color;
            }
        }

        svg {
            width: 20px;
            height: 20px;

            path {
                stroke: $logo1-color;
            }
        }

        a {
            color: $logo1-color;
        }
    }

}

.edit-details-wrap {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
}

.edit-info-icon {
    width: 20px;
    height: 20px;
}

.edit-info-pop {
    width: 300px;
}
.popoverWidth{
    width: 400px;
}
.view-record-link {
    color: $logo1-color;
    cursor: pointer;

    &:hover {
        color: $logo2-color;
    }
}
.ant-drawer-content-wrapper{
    min-width: 600px;
    .ant-drawer-body{
        padding: 14px;
    }
    .ant-drawer-header-title{
        button{
            order: 2;
            svg{
                fill: #fff;
            }
        }
        .drawer-title{
            color: #FFF;
        }
    }
}
.ant-drawer-header{
    background-color: $logo1-color;
}
.allow {
    p {
        font-size: 14px;
        font-family: $semiBold;
    }

    .check-box-wrap {
        display: flex;
        align-items: center;
        gap: 4px;

        .check-box-label {
            font-size: 14px;
        }
    }
}

.upload-photo-modal {
    z-index: 9999;

    .popups {
        .addpopups {
            width: 350px !important;

            .popBody {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .footer-btn {
            button {
                padding: 10px 20px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                height: auto;
            }

            .yes-button {
                color: #fff;
            }
        }

        .ant-avatar-image,
        .ant-avatar {
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
        }

        .ant-avatar-string {
            font-size: 30px;
        }
    }
}

.border-wrap {
    border: 1px solid #c6c6c6;
    padding: 10px;
    border-radius: 6px;

    .help-text {
        color: #999;
        font-size: 12px;
    }
}

.phone-num-wrap {
    display: flex;
    gap: 10px;
        input[type="tel"]{
            border: 2px solid #CCABCD;
            border-radius: 6px;
            &:focus{
                border: 2px solid #2c0036!important;
            }
        }
    .phone-num {
        flex: 2;
    }

    .extension {
        flex: 1;
    }

    .PhoneInputInput {
        height: 100%;
        padding: 4px 8px;
    }
}

.removelink-wrap {
    .removelink {
        color: $logo1-color;
        cursor: pointer;
        font-size: 13px;
        &:hover {
            color: $logo2-color;
        }
    }

    svg {
        margin-left: 8px;
        width: 18px;
        height: 18px;
        fill: #999;
    }
}

.ant-tooltip-inner {
    font-size: 12px;
}

.view-record-link{
    font-size: 14px;
}
.association-wrapper {
    .association-body{
        max-height: 28vh;
        overflow: auto;
        p{
            font-size: 13px;
            margin: 0;
        }
    }
    .association-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        .view-record-link{
            font-size: 14px;
        }

        h6 {
            margin-bottom: 0;
            font-size: 14px;
            font-family: $commonfntFmly;
        }

        a {
            text-decoration: none;
        }
    }

    .association-card {
        border: 1px solid #dee2e6;
        border-radius: 5px;
        padding: 4px 8px;
        margin-bottom: 6px;
        .appointment-date{
            display: flex;
            .select-appointment{
                border: none;
                padding:0 4px;
                display: block;
                height: auto;
                font-size: 12px;
                .dropdown-input{
                    display: flex;
                    align-items: center;
                    svg{
                        position: unset;
                    }
                    input{
                        color: $logo1-color;
                        font-family: $commonfntFmly;
                        width: auto!important;
                        &:focus-visible{
                            outline: none;
                            border: none;
                        }
                       }
                }
                .dropdown{
                    top: 20px;
                    li{
                        font-size: 12px;
                    }
                }
            
            }
            &.deal-preview{
                .select-appointment{
                    width: auto;
                }
            }
            .selectdropdown{
                .dropdown-input{
                    width: 100%;
                    input{
                        width: 100%;
                        border: none;
                    }
                }
            }
            label{
                font-size: 12px;
            }
        }
        p {
            margin-bottom: 4px;
            font-size: 12px;
        }

        .association-name {
            cursor: pointer;
            color: #2c0036;
            font-size: 14px;
            font-weight: bold;

            &:hover {
                text-decoration: underline;
            }
        }

        .association-email,
        .association-phone {
            font-size: 12px;
        }
        .association-email.domain{
           span {
            cursor: pointer;
            color: #2c0036;
            &:hover {
                text-decoration: underline;
            }
        }
        }

        svg {
            color: #A563A6;
            // fill: #A563A6;
            cursor: pointer;
        }

        position: relative;
        .primary-tag{
            color: #A563A6;
            border: 1px solid #A563A6;
            background-color: #a563a61a;
            left: -8px;
            top: -5px;
        }
        .more-button{
            position: absolute;
            border: 1px solid #2c0036;
            right: 0;
            top: 0;
            border-radius: 5px;
            font-size: 12px;
            padding: 2px 8px;
            color: #fff;
            background: #2c0036;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
        }
        &:hover{
            .more-button{
                visibility: visible;
                opacity: 100%;
            }
        }

    }
}
.closedate{
    label{
        font-size: 14px;
    }
}
.ant-drawer-content-wrapper{
    transform:translate(0)!important;
}
.ant-drawer-body{
    .nav-item{
        button{
            color: #999;
            font-size: 14px;
        }
        .active{
            background-color: $logo1-color!important;
            color: #fff!important;
        }
    }
}
.association-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 92%;
    background: white;
    padding: 10px 0;
    .next-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;

        p {
            padding-right: 8px;
            margin-bottom: 0;
            font-size: 14px;
        }
    }
}
.existingContact{
    .nav-item{
        button{
            font-size: 14px;
        }
    }
}
.existing-form {
    display: flex;
    flex-direction: column;
.association-step-two{
    h6{
        font-size: 13px;
        margin: 0;
    }
    &:last-child{
        padding-bottom: 85px;
    }
    .fontweight{
        font-family: $semiBold;
    }
    .ant-radio-wrapper{
        span{
            font-size: 13px;
        }
    }
}
    .form-wrapper {
        height: 95%;
        .search-box{
            font-size: 13px;
        }
        .association-list {
            height: 70vh;
            margin-bottom: 12px;
            border-bottom: 1px solid #dee2e6;
           
            .form-check-label{
                width: 100%;
            }

            .checkbox-label {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 13px;
            }
        }
    }

    .association-step-two {
        height: 78vh;
    }
}

.delete-primary-company{
    z-index: 9999 !important;
    .addpopups{
        width: 35%;
    }
    .delete-options{
        .selectdropdown {
            .dropdown{
                position: fixed;
                width: 33%;
                top: 55%;
            }
        }
    }
    .popBody {
        // min-height: 200px;

        .instruction{
            margin-bottom: 8px !important;
            padding-left: 4px;

            &.sub-instruction{
                font-weight: normal;
                margin-bottom: 20px !important;
            }
        }
    }
}
@media screen and (max-width:780px){
    .ant-drawer-content-wrapper{
        min-width: 400px;
    }
}