.file-upoad-wrap{
    .ant-upload-wrapper {
        display: block;
    }
}
.btn-height{
    height: auto;
}
.ant-upload-list-item{
    background-color: #fff;
    &:hover{
        background-color: #fff!important;
    }
}