

  .tooltipContainerStyle{
    position: fixed;
    top: 132px; // Adjust this as needed
    // left: -10%;
    transform: translateX(-50%);
    z-index:  1000;
    width: 400px;
    box-shadow: 2px 1px 15px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .tooltipStyle{
    background-color: #ffffff;
    color: '#fff';
    padding: 20px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #ddd;
    &::before, &::after {
      content: '';
      position: absolute;
      bottom: 100%;
      border: 10px solid transparent;
      border-bottom-color: white;
      left: 50%;
      margin-left: -10px;
    }
    
    &::before {
      border-width: 11px;
      border-bottom-color: #ddd;
      margin-left: -11px;
    }
  }
  .tooltipTriangleStyle{
    position: absolute;
    top: -5px; // Adjust this as needed
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #333;
  }