@import '../SCSS/cssVariable.scss';
.select-container {
    // width: 400px;
    border: 2px solid #CCABCD;
    border-radius: 5px;
    padding: 10px;
    background-color: #fff;
    .select-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
    }
    .category-header{
        font-family: $semiBold;
    }
    .dropdown{
        margin-top: 6px;
        background-color: #fff;
        position: absolute;
        transform: translate3d(-10px, 0, 0px);
        width: 396px;
        padding: 5px 12px;
        border: 1px solid #e9e9e9;
        border-radius: 0 0 6px 6px;
        z-index: 999;
    }
  }
 .popups{
    .bulk-edit-popup{
        background-color: #fff;
        border-radius: 10px;
        width: auto;
        min-width: 480px;
        overflow: auto;
        .customer-body{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
      }
 }
  .header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .search-box {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .options {
    max-height: 150px;
    overflow-y: auto;
  }
  
  .option {
    padding: 8px;
    cursor: pointer;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  .selected {
    background-color: #f0daf1;
  }