@import '../SCSS/cssVariable.scss';
.deals-report-wrap{
    display: flex;
    gap: 20px;
    padding: 10px 20px;
    justify-content: space-between;
    box-shadow: 1px 0px 5px 3px rgb(161 161 161 / 38%);
    margin: 14px;
    border-radius: 10px;
    .report-col{
        text-align: center;
        p{
            color: #999;
            font-size: 12px;
        }
        .label{
            color: $logo1-color;
            font-size: 15px;
            font-family: $commonfntFmly;
            margin-bottom: 6px;

        }
        h3{
            font-family: $semiBold;
            font-size: 22px;
        }
    }
}
.personal-info-wrap{
    .deals-details-wrap{
        width: 100%;
        display: flex;
        gap: 20px;
        align-items: flex-start;
        .personal-info{
            // width: 100%;
            width: 80%;
                h6 {
                        font-family: $semiBold;
                        margin-bottom: 18px;
                    }
                    .closedate, .appointment-date{
                        display: flex;
                        align-items: center;
                        gap: 3px;
                    }
                
                    .appointment-date{
                        label{
                            font-size: 14px;
                        }
                        .appointment-scheduled{
                                border: none;
                                padding: 0;
                        }
                        .select-appointment{
                            border: none;
                            height: auto;
                            padding-left: 2px;
                            .dropdown-input-field{
                                font-family: $semiBold;
                                color: $logo1-color;
                                border: none;
                                &:focus, &:focus-visible{
                                    border: none;
                                    box-shadow: none;
                                    outline: none;
                                }
                                &::placeholder{
                                    color: $logo1-color;
                                }
                             
                            }
                            .dropdown{
                                top: 30px;
                            }
                            svg{
                                width: 24px;
                                height: 24px;
                            }
                            .dropdown-input{
                                display: flex;
                                svg{
                                    position: unset;
                                }
                            }
                        }
                    }
                    .closedate{
                        margin-top: 5px;
                        .myDatePicker{
                            width: auto;
                            border: 2px solid #CCABCD!important;
                            height: 30px;
                            padding: 0 10px!important;
                        }
                    }
        }
    }
}
.action-wrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 8px;
    padding-right: 10px;
    button{
        padding:6px 14px;
        font-size: 12px;
    }
}
// .offcanvas-backdrop{
//     z-index: 888888!important;
// }
// .offcanvas-end{
//     z-index: 999999!important;
//     width: 45%!important;
// }

.contact_timeline{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
}

.timeline-field{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    font-size: 12px;
    flex-direction: column;
    .form-select{
        // width: 25% !important;
        // margin-left: 18px;
        font-size: 12px;
    }
    // .timeline-field-date-picker{
    //     width: 25%;
    //     border: 1px solid #CCABCD !important;
    //     padding: 4px !important;
    // }
}
// .deal-amount-cell{
//     text-align: right;
// }
.deal-company-column {
    .ant-avatar {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
        font-size: 14px !important;

        .ant-avatar-string {
            line-height: 30px !important;
        }
    }
}

.company-link {
    color: #A563A6;
    font-size: 12px;
    margin-top: 3px;
    cursor: pointer;
    font-weight: 600;
    &:hover{
      text-decoration: underline;
    }
}

.deal-company-column-popover{
    .ant-popover-inner{
        max-height: 250px !important;
        overflow-y: auto !important;
    }
}