@import '../SCSS/cssVariable.scss';


.jobscode {
    width: 100%;
    margin-top: 6px;
    table {
        width: 100%;
        min-width: auto;
        th {
            @include tableth();
            padding: 10px;
        }

        td {
            @include tabletd();
            padding: 10px;
        }

        tr {
            border-left: 1px solid $light-color;
            border-right: 1px solid $light-color;
        }

        tr:nth-child(even) {
            background-color: $light-color;
        }
        tr:nth-child(odd) {
            background-color: $white-color;
        }
    }
}

.settingPage{
    // height: 96.5%;
    border-radius: 10px;
    overflow: hidden;
    background-color: $white-color;
    box-shadow: 0px 3px 6px rgba($color: #A563A6, $alpha: 0.54);
    .content{
        display: none;
    }
    
    .active-content{
        display: block;
        padding-left: 20px;
        padding-right: 20px;
        .ListHieght{
            padding: 10px;
        }
    }
    .header{
        background-color: $logo1-color;
        color: $white-color;
        font-size: 18px;
        align-items: center;
        margin: 0;
    }
    .content-tabs {
        flex: 70%;
        th{
            font-size: 14px;
        }
        td{
            font-size: 12px;
        }
    }
    .tab-container{
        display: flex;
        flex-wrap: wrap;
    }
    .Column1 {
        // overflow-y: auto;
        // min-width: 340px;
        flex: 30%;
        
    .OnlyShadow{
        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        height: 100%;
        .InShadow{
            width: 100%;
            height: 100%;
            // box-shadow: -3px -3px 6px rgba(0, 0, 0, 0.2) inset;
        .ProfileInfo{
            padding-top: 25px;
            .ProfileMenu{
                overflow-y: auto;
                overflow-x: hidden;
                height: 38vh;
                .ProfileTab{
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    // padding-right: 90px;
                    font-size: 14px;
                    display: block;
                    position: relative;
                    text-decoration: none;
                    color: $primary-color;
                    transition: 0.5ms all ease-in-out;
                    cursor: pointer;
                  &:hover{
                    color:  $logo2-color;
                    transition: 0.2s all ease-in-out;
                  }
                    .ProfileTab-Arrow {
                        font-size: 2rem;
                        line-height: 1;
                        position: absolute;
                        left: 250px;
                        display: none;
                        color: $primary-color;
                        animation-name: example2;
                        animation-duration: 0.6s;
                        animation-timing-function: ease-out;
                       
                    }
                }
                .ProfileTab-active {
                    position: relative;
                    padding-left: 15px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    background-color: $bgGrey-color;
                    // box-shadow: 6px 0px 5px rgba(0, 0, 0, 0.3) inset;
                    transition: 0.5ms all ease-in-out;
                    font-size: 14px;
                    border-left: 6px solid $logo2-color;
                    color:  $logo2-color;
                    .ProfileTab-Arrow {
                        display: inline;
                        color: $primary-color;
                        font-size: 2rem;
                        line-height: 1;
                        right: 0;
                        margin-top: -7px;
                        position: absolute;
                        display: none;
                    }
                }
               
                
                @keyframes example3 {
                    from {
                        left: 200px;
                    }
                
                    to {
                        left: 275px;
                    }
                }
            

            }
        }
        }
    }
}
.row-style{
    gap: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
    .report-job-status{
        position: relative;
        .label{
            position: absolute;
            font-size: 12px;
            left: 14px;
            top: 10px;
            z-index: 1;
            color: $primary-color;
            font-family: $commonfntFmly;
        }
        .ant-select-selector{
            transform: translate(-11px, 10px);
        }
        :where(.css-dev-only-do-not-override-p7e5j5).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
            box-shadow: none;
        }
    }
   }
   .Column{
    flex: 1;
   }
   .date-range-wrap{
    height: 100%;
    .date-range-picker{
        flex: 1;
        height: 100%;
        .ant-picker {
            height: 58px;
            // height: 100%;
        }
    }
   }
}





.jobcodeBtn {
    width: max-content;
    float: right;
    padding: 5px 10px;
    background-color: rgb(255, 255, 255);
    color: $primary-color;
    margin-left: 10px;
}

.headBtn {
    width: max-content;
    float: right;
    padding: 5px 10px;
    font-size: 12px;
    svg{
        width: auto!important;
        height: auto !important;
    }
}

.overFlowScroll {
    height: 90vh;
    overflow-y: auto;
}

.overFlowScroll::-webkit-scrollbar,
.ListHieght::-webkit-scrollbar {
    background-color: $lightLogo1-color;
    width: 7px;
    border-radius: 30px;

}

.overFlowScroll::-webkit-scrollbar-thumb,
.ListHieght::-webkit-scrollbar-thumb {
    background-color: $lightLogo1-color;
    border-radius: 30px;
}

*::-webkit-scrollbar:hover {
    width: 12px;
    transition: width 0.5s ease-in;

}

*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

.content-tabs{
    background-color: $bgGrey-color;
}


.ListHieght{
    height: 40vh;
    overflow-y: auto;
    // padding: 10px;
    background-color: $white-color;

    .list-spinner{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .empty-data{
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
.setting-tab-popup-content{
    gap: 10px;
    .input-width{
        flex: 1;
    }
}
@media screen and (max-width:992px) {
    .tab-container{
        .Column1{
            flex: 100%;
            .ProfileMenu{
                display: flex;
                gap: 10px;
                text-align: center;
                height: auto!important;
                .ProfileTab-active{
                    border-top: 6px solid $logo2-color;
                    border-left: none!important;
                    // padding-right: 10px!important;
                    font-size: 14px!important;
                    padding: 4px!important;

                }
                .ProfileTab{
                    padding: 4px!important;
                    border-top: 6px solid transparent;
                    font-size: 14px!important;

                }
            }
        }
    }
    .settingPage{
        .active-content{
            padding: 5px;
        }
    }
}
@media screen and (max-width:480px){
    .settingPage {
        .Column1 {
            .OnlyShadow {
                .InShadow {
                    .ProfileInfo {
                        padding-top: 0;
                        .ProfileMenu{
                            flex-wrap: wrap;
                            padding: 10px;
                            gap: 6px;
                            .ProfileTab, .ProfileTab-active{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .ListHieght{
            height: 28vh;
        }
}
.report-filter-wrap{
    flex-wrap: wrap;
    .date-range{
        padding-right: 0!important;
    }
    .w-50{
        width: 100%!important;
    }
  
}
.setting-tab-popup-content{
    .input-width{
        flex:100%;
    }
}
.jobscode{
    margin-top: 0;
}
    }