@import '../SCSS/cssVariable.scss';

@mixin tableIcon($bgColor: '#000000') {
    background-color: $bgColor;
    width: 25px;
    height: 25px;
}

@include contentHearderBtn();
.ant-table-cell{
    .input-wrap{
        input{
            font-size: 14px;
        }
        .form-check{
            display: flex;
            align-items: center;
            gap: 10px;
        }
        select{
            height: 35px;
            font-size: 14px;
        }
    }
}

.custpage {
    padding: 0px;
    .date-cvc{
        display: flex;
        gap: 15px;
    }
    .myInputBox{
        .ant-picker{
            height: 25px!important;
        }
    }
    .add-card-inner{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        .date-cvc{
            display: flex;
            gap: 15px;
        }
        .flex-item{
            flex: 30%;
            .ant-picker{
                height: 25px!important;
            }
            .myDatePicker{
                &:hover{
                    border-color:transparent!important ;
                }
            }
        }
    }
    .accordion{
        .button[aria-expanded="true"]{
            background-color: $light-color;
        }
        .add-row-wrap{
            .input-wrap{
                display: flex;
                flex-direction: column;
            }
        }
    }
    .credit-card-wrapper{
        border-radius: 10px;
        overflow: hidden;
        .ant-table-wrapper{
            table{
                td{
                  
                   &:first-child{
                    width: 20%!important;
                   }
                   &:nth-child(2){
                    width: 5%!important;
                   }
                   &:nth-child(5){
                    width: 20%!important;
                   }
                   &:nth-child(6){
                    width: 20%!important;
                   }
                }
            }
        }
    }
    .header{
        background-color: $logo1-color;
        color: $white-color;
        font-size: 18x;
        align-items: center;
    }
.ant-upload-wrapper{
    .button{
        height: auto;
    }
}
.ant-upload-list-item{
    background-color: $white-color;
    &:hover{
        background-color: $light-color!important;
    }
}
    .custTable::-webkit-scrollbar {
        background-color: $lightLogo1-color;
        width: 10px;
        border-radius: 30px;

    }

    .custTable::-webkit-scrollbar-thumb {
        background-color: $lightLogo1-color;
        border-radius: 30px;
    }

    *::-webkit-scrollbar:hover {
        width: 10px;
        transition: width 0.5s ease-in;

    }

    *:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 30px;
    }



    .custTable {
        width: 100%;
    }

    .bottomBar {
        position: absolute;
        bottom: 0px;
        background-color: $white-color;
        width: 100%;

        .btmwidth {

            box-shadow: 0px -4px 4px rgba($color: #000000, $alpha: 0.25);

            .btmbtn {
                display: inline;
                width: fit-content;
            }

            .prevBtn {
                background-color: $light-color3;
                margin-right: 20px;
            }
        }
    }

    table {
        width: 100%;
        min-width: 1000px;
        overflow: auto;
     
        thead {
            position: sticky;
            top: 0;
            z-index: 99;
        }

        th {
            @include tableth();
        }

        td {
            @include tabletd();
        }

        td:nth-child(1), th:nth-child(1) {
            width: 5% !important;
        }
        td:nth-child(2),th:nth-child(2) {
            width: 21% !important;
        }

        td:nth-child(3),th:nth-child(3) {
            width: 21% !important;
        }

        td:nth-child(4),th:nth-child(4) {
            width: 21% !important;
        }

        td:nth-child(5), th:nth-child(5) {
            width: 11%!important;
        }
        td:nth-child(6), th:nth-child(6) {
            width: 11%!important;
        }
        td:nth-child(7), th:nth-child(7) {
            width: auto!important;
        }

        tr:nth-child(even) {
            background-color: $light-color;
        }

        tr:hover {
            background-color: $light-color3;
        }

        tr {
            cursor: pointer;
        }

        .grpofBtn {
            display: flex;
            column-gap: 10px;
            align-items: center;
            justify-content: center;
            height: 100%;

            .website {
                @include tableIcon($white-color);
            }

            .jobs {
                @include tableIcon($white-color);
                color: $primary-color;
            }

            .task {
                @include tableIcon($white-color);
                color: $primary-color;
            }

            .bill {
                @include tableIcon($white-color);
                color: $primary-color;
            }

            .pass {
                @include tableIcon($white-color);
                color: $primary-color;
            }

            .website:hover,
            .jobs:hover,
            .task:hover,
            .bill:hover,
            .pass:hover {
                color: $white-color
            }
        }
    }
}

  .more-option-wrap{
    padding: 0px 5px;
    .more-option {
        button {
            svg{
              fill: $primary-color;
                }
          &:hover {
            svg {
              fill: $white-color;
            }
          }
        }
        a {
          font-size: 14px;
        }
        .dropdown-toggle {
          background-color: transparent;
          padding: 0;
          border: none;
         
         
          &::after {
            display: none;
          }
        }
      }
      .dropdown-menu{
        a{
            margin-bottom: 10px;
            align-items: center;
            display: flex;
            &:last-child{
                margin-bottom: 0;
               
            }
            &:hover{
                background-color: $light-color;
                svg{
                    fill: $primary-color;
                }
            }
            svg{
                width: 20px;
                height: 18px;
                margin-right:10px;
              }
        }
       
      }
  }
  .accordion-button:not(.collapsed){
    background-color: $light-color!important;
  }
  .accordion-button:focus{
    box-shadow: none!important;
  }
  .input-wrap{
    width: 100%;
    .ant-picker{
        border: 2px solid #CCABCD;
        border-radius: 4px;
        &:focus{
            outline: none;
            border: 2px solid $primary-color;
            box-shadow: none;
        }
        input{
            border: none;
            &:focus{
                border: none;
            }
        }
       
    }
    .form-check{
        padding-top: 7px;
    }
    input{
        font-size: 14px;
        border: 2px solid #CCABCD;
        border-radius: 4px;
        &:focus{
            outline: none;
            border: 2px solid $primary-color;
            box-shadow: none;
        }
    }
    select{
        height: auto;
        font-size: 14px;
        border: 2px solid #CCABCD;
        border-radius: 4px;
        &:focus{
            outline: none;
            border: 2px solid $primary-color;
            box-shadow: none;
        }
    }
}
  .add-row-wrap{
    display: flex;
    gap: 20px;
    width: 100%;
    margin-bottom: 10px;
    // align-items: center;
    .input-wrap{
        width: 100%;
        .ant-picker{
            border: 2px solid #CCABCD;
            border-radius: 4px;
            width: 100%;
            &:focus{
                outline: none;
                border: 2px solid $primary-color;
                box-shadow: none;
            }
            input{
                border: none;
                &:focus{
                    border: none;
                }
            }
           
        }
        .form-check{
            padding-top: 7px;
        }
        input{
            font-size: 14px;
            border: 2px solid #CCABCD;
            border-radius: 4px;
            &:focus{
                outline: none;
                border: 2px solid $primary-color;
                box-shadow: none;
            }
        }
        select{
            height: auto;
            font-size: 14px;
            border: 2px solid #CCABCD;
            border-radius: 4px;
        }
    }
    .add-card-btn{
       button{
        background-color: $primary-color;
        border: none;
        color: $white-color;
        border-radius: 4px;
        padding: 7px 12px;
        &:hover{
            background-color: $logo2-color;
        }
        svg{
            fill: $white-color;
        }
       }
    }
  }
  .centerpopups{
    .addpopups{
       .customer-body{
        .header {
            div{
                font-size: 14px;
            }
        }
        table{
            td{
                &:nth-child(6){
                    min-width: 230px;
                }
            }
        }
       } 
    }
  }
  .service-rate-wrap{
    table{
        min-width: auto;
        td{
            &:last-child{
                width: 1%!important;
            }
        }
    }
  }
  .text-danger{
    font-size: 12px;
  }
 .centerpopups{
    .popups{
       .customer-popup{
        width: 90%;
       }  
    //    .add-creditcard-modal{
    //     width: 60%;
    //    }
    }
 }
//  .password-page{
//     table{
//         td{
//             min-width: 195px;
//             width: 200px;
//             max-width: 260px;
//         }
//     }
// }
//Pagination 
.ant-pagination{
    li{
        margin-inline-end:6px!important
    }
}
.action-btn-wrap{
    .action-btn{
        display: flex;
    }
}
@media screen and (max-width:1200px){
    .centerpopups {
        .popups{
            .add-creditcard-modal{
                width: 80%;
               }
        }
    }
 
}
@media screen and (max-width:1082px){
    .custpage{
        .add-card-inner{
            .flex-item{
                flex: 40%;
            }
        }
    }
}
@media screen and (max-width:992px) {
    .custpage{
        .accordion{
            .accordion-item{
                .add-row-wrap{
                    flex-wrap: wrap;
                    .input-wrap{
                        flex: 25% 1;
                    }
                }
            }
        }
       
    }
}
@media screen and (max-width:772px) {

    .add-row-wrap{
        flex-wrap: wrap;
        .input-wrap{
            flex: 45% 1!important;
        }
    }
    
}
@media screen and (max-width:612px){
    .custpage{
        .add-card-inner{
            .flex-item{
                flex: 50%;
            }
        }
    }
}
@media screen and (max-width:480px){
 .add-row-wrap {
        flex-wrap: wrap;
        gap: 10px!important;
        .input-wrap{
            flex: 100%!important;
        }
    }
    .custpage{
        .add-card-inner{
            .flex-item{
                flex: 50%;
            }
        }
    }
}