@import '../SCSS/cssVariable.scss';


.multiselect-wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid #CCABCD;
    // height: 58px;
    background-color: #fff;
    color: $primary-color;
    border-radius: 6px;
    padding: 10px;
    align-items: flex-start;
    justify-content: center;
    .multiselect-label{
        font-size: 12px;
        font-family: $commonfntFmly;
    }
    .ant-select-selector{
        border: none!important;
        padding-left: 0!important;
        &:focus-visible, &:focus{
            box-shadow: none!important;
            outline: none!important;
        }
    }

}