@import '../SCSS/cssVariable.scss';
::-webkit-scrollbar {
    background-color: $lightLogo1-color;
    width: 0px;
    border-radius: 30px;
  
  }
  .header-container{
    .selectBox-wrap{
        display: flex;
        align-items: center;
        label{
            color: #fff;
            font-size: 12px;
            width: 40%;
            text-align: center;
        }
        select{
            border: 2px solid #CCABCD !important;
            font-size: 13px;
            width: 60%;
        }
    }
    .search-box-wrap{
        min-width: 25%;
        position: relative;
        .global-search-note{
            font-size: 12px;
            color: #e8e8e8;
        }
        input{
        padding: 7px;
        border-radius: 100px;
        border: none;
        padding-left: 45px;
        width: 300px;
        &::placeholder{
            font-size: 12px;
        }
        }
    svg{
        position: absolute;
        top: 33%;
        left: 14px;
        width: 15px;
        height: 15px;
    }
    }
    .center-content{
        display: flex;
        align-items: center;
        // width: 83%;
        .search-box-wrap{
            flex: 1.5;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            margin-left: 12px;
            margin-right: 12px;
        }
        .user-title{
            // flex: 1.5;
            p{
                font-size: 16px;
                color: #fff;
                font-family: $commonfntFmly;
            }
        }
        .inner-searchbox{
            // width: 60%;
            position: relative;
        }
    }
  .profile-text{
    // width: 30%;
    justify-content: flex-end;
  }
  }
.headbg{
    background-color: $logo1-color;
    position: absolute;
    width: 100vw;
    height: 60px;
    z-index: 99;
    .notificationCount{
        top: 0;
        right: 5px;
        width: 15px;
        height: 15px;
        border-radius: 50px;
        text-align: center;
        line-height: 1;
        font-size: 0.3em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        color: $white-color;
    }
    .myIcon{
        @include icons();
        font-size: 2em;
        cursor: pointer;
    }
    .topSection{
        height: 60px;
        width: max-content;
        background-image: linear-gradient(to right, $primary-color,$primary-color, $logo1-color);
        margin-left: -10px;
        // background-color: $primary-color;
      
        .smallLogo{
            padding-right: 10px;
            border-right: 1px solid $white-color;
        }
        .mobilelogo{
            display: none;
        }
    }
    .wdth-20{
        width: 20%;
    }
    .userName{
        color:$white-color;
        font-family: $commonfntFmly;
        text-align: right;
    }
    .userDesig{
        color: $white-color;
        font-family: $lightFntFmly;
        text-align: right;
    }
    
}
.profileIcon{
    width: 40px;
    height: 40px;
    background-color: $white-color;
    border-radius: 50px;
    font-size: 1.2em;
}
.notification-wrap{
    .notiication-description{
        font-family: $RFntFmly;
        font-size: 14px;
    }
   
    .noti-user-wrap{
        display: flex;
        align-items: flex-start;
        gap: 10px;
        background-color: #fff;
        padding:10px 8px;
        border-radius: 10px;
        border: 1px solid #999;
        margin-bottom: 10px;
        .user-profile{
            min-width: 35px;
            height: 35px;
            border-radius: 100px;
            // border: 1px solid #c7c7c7;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: orange;
           .initial-text{
            font-size: 14px;
           }
        }
        .user-info{
            .name-time{
                display: flex;
                align-items: center;
                gap: 10px;
                .date{
                    color: #999;
                    font-family: $RFntFmly;

                }
                .post-time{
                    font-style: italic;
                    font-family: $RFntFmly;
                    margin: 0;
                }
            }
            .user-name{
                font-size: 14px;
            }
            .post-time{
                font-size: 12px;
                margin: 0;
            }
        }
    }
    .notiication-description{
        font-size: 14px;
        margin: 0;
    }
    .new-noti{
        background-color: #fee5ff;
        padding:10px 8px;
        border-radius: 10px;
        border: 1px solid $primary-color;
    }
    .noti-icon{
        height: 30px;
        width: 30px;
    }
}
.notification-wrap {
    min-width: 450px;
}
.offcanvas-title{
    display: flex;
    align-items: center;
    width: 101%;
    justify-content: space-between;
}
.clear-btn{
    text-align: right;
    padding: 0 20px;
    margin-bottom: 0px;
    cursor: pointer;
    color: $primary-color;
    text-decoration: underline;
    font-size: 16px;
    &:hover{
        color: $logo2-color;
    }
}
@media screen and (max-width:1024px){
    .header-container .profile-text{
        width: 45%;
    }
}
@media only screen and (max-width: 844px){
    .smallLogo{
        width: 20vh;
    }
    .userName{
        display: none;
    }
}
@media only screen and (max-width: 540px){
    .notification-wrap {
        min-width: 320px!important;
        width: 320px!important;
    }
    .headbg .topSection{
        .smallLogo{
            display: none;
        }
        .mobilelogo{
            display: block;
            width: 42px;
        }

    }
    .header-container{  
        .center-content{
            .inner-searchbox{
                width: 100%;
                input{
                    font-size: 12px;
                    padding-left: 30px;
                }
                svg{
                    width: 14px;
                    height: 14px;
                    top: 32%;
                }
            }
        }
    }
    .header-container .profile-text{
        width: auto;
        padding-right: 0!important;
    }
    .profileIcon{
        width: 34px;
        height: 34px;
        font-size: 12px;
    }
}

