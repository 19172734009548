@font-face {
    font-family: arboriaBook;
    src: url(../../../public/Arboria-Book.ttf);
}

@font-face {
    font-family: arboriaMedium;
    src: url(../../../public/Arboria-Medium.ttf);
}

@font-face {
    font-family: bebasNeue;
    src: url(../../../public/BebasNeue-Regular.ttf);
}
@font-face {
    font-family: poppinsRegular;
    src: url(../../../public/Poppins-Regular.ttf);
}

@font-face {
    font-family: poppinsMedium;
    src: url(../../../public/Poppins-Medium.ttf);
}

@font-face {
    font-family: BebasNeue;
    src: url(../../../public/BebasNeue-Regular.ttf);
}
@font-face {
  font-family: poppinsSemiBold;
  src: url(../../../public/Poppins-SemiBold.ttf);
}
@font-face {
    font-family: poppinsBold;
    src: url(../../../public/BebasNeue-Regular.ttf);
}

//set a color
$primary-color: #2c0036;
$logo1-color: #A563A6;
$logo2-color: #e99d22;
$lightLogo1-color: #EDE7EE;
$white-color: #ffffff;
$light-color: #F6EFF6;
$light-color3: #E4D0E4;
$light-primary: #C8BACB;
$bgGrey-color: #efefef;
$blurBg: '../../images/BlurBg.png';

//set a fount-size
$largeFntSize: 32px;
$mainFntSize: 16px;
$commonFntSize: 14px;
$smallFntSize: 10px;

//set a font-family
$commonfntFmly: poppinsMedium;
$lightFntFmly: poppinsMedium;
$capFntFmly: BebasNeue;
$RFntFmly: poppinsRegular;
$semiBold: poppinsSemiBold;

//set a shadow
$insetShadow: inset -1px -1px 2px rgba(0, 0, 0, 0.25);
$shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

//set mixin 
@mixin icons($iconColor: #fff) {
    padding: 0px 5px;
    font-size: 2.5em;
    color: $iconColor;
}
@mixin borderStyle() {
  border: 1px solid #b9b9b9;
  border-radius: 6px;
}
@mixin InnerShadow() {
  box-shadow: inset 5px -1px 12px rgba(13, 0, 0, 0.2);
}
@mixin PadTable() {
    padding: 30px 10px 20px 10px;
}

@mixin tableth() {
    padding: 10px 5px;
    border-bottom: 2px solid $primary-color;
    background-color: $light-color3;
    position: sticky;
    top: 0px;
}

@mixin tabletd() {
    padding: 10px 5px;
    font-family: $lightFntFmly;
    line-height: 1;
}

@mixin tableIcon($bgColor: '#000000') {
    background-color: $bgColor;
    width: 25px;
    height: 25px;
}

@mixin InputFields() {
    label::after {
        background-color: transparent !important;
        font-family:$commonfntFmly ;

    }

    label {
        color: $primary-color !important;
        font-family:$commonfntFmly ;
    }

    .form-control {
        border: none;
        border: 2px solid #CCABCD;
        border-radius: 5px;
        font-size: 14px;
    }


    .form-control:focus {
        border: none;
        border: 2px solid $primary-color;
        box-shadow: none;
        outline: none;
        
    }

    .form-control:focus~label::after {
        color: $primary-color !important;
        background-color: transparent !important;
    }

    .form-control:focus~label {
        color: $primary-color !important;
    }

    .form-select {
        border: none;
        border: 2px solid #CCABCD;
        border-radius: 5px;
        

    }

    .form-select:focus {
        border: none;
        border: 2px solid $primary-color;
        box-shadow: none;
        outline: none;
            }

    .form-select~label::after {
        color: black !important;
        background-color: transparent !important;
    }

    .form-select~label {
        color: $primary-color !important;
    }

}

@mixin primaryButton(){
    .button{
        background-color: $primary-color;
        color: $white-color;
        padding: 10px 20px;
        width: 100%;
        border-radius: 20rem;
        border: none;
        box-shadow: $insetShadow;
        box-shadow: $shadow;
        font-family: $commonfntFmly;
        font-size: $commonFntSize;
        display: inline;
        width: fit-content;
    }
    .button:hover{
        background-color: $logo2-color;
        color: $primary-color;
    }
}
span[variant="success"]{
  display: inline-block;
}
.mainpopups{
    position: absolute;
   width: 100vw;
    height: 100vh;
    z-index: 99;
    top: 0;
    right: 0;
    // transition: right 1s ease-in-out;
    .blurBg{
      background: rgba(0,0,0, 0.6);
      backdrop-filter: blur(5px);
      height: 100%;
      z-index: -99;
      position: relative;
    }
    @keyframes in-out {
      0% {
        width: 0vw;
      }
      100% {
        width: 100vw;
      }
    }
  }
  
  .nomainpopups{
    position: absolute;
    width: 100vw;
   top:0;
   right:-100%;
    // transition: right 1s ease-in-out;
  }
  
.centerpopups{
    position: absolute;
   width: 100vw;
    height: 100vh;
    z-index: 999;
    top: 0;
    right: 0;
    // transition: top 0.5s ease-in-out;
    .blurBg{
        background: rgba(0,0,0, 0.6);
      height: 100%;
      z-index: -99;
      position: relative;
      background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(5px);
    }
    @keyframes in-out {
      0% {
        width: 0vw;
      }
      100% {
        width: 100vw;
      }
    }
  }
  
  .nocenterpopups{
    position: absolute;
    width: 100vw;
   top:-100%;
   right:0%;
    // transition: top 0.5s ease-in-out;
  }

  @mixin contentHearderBtn(){
      .myBtnStyle{
        background: transparent;
        color: $logo2-color;
        font-size: 0.9em;
        border: 2px solid $logo2-color;
        cursor: pointer;
    }
    .myBtnStyle:hover{
        background-color: $logo2-color;
        transition: all 0.3ms;
        color: $primary-color;
    }
  }

  @mixin tableScroll(){
    .custTable::-webkit-scrollbar{
        background-color:$lightLogo1-color;
        width: 10px;
        border-radius: 30px;
        
    }
    
    .custTable::-webkit-scrollbar-thumb{
        background-color:$lightLogo1-color;
        border-radius: 30px;
    }
    *::-webkit-scrollbar:hover {
        width: 10px;
        transition: width 0.5s ease-in;
        
      }
    *:hover::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 30px;
    }
  }

  @mixin tableLoading(){
    .lds-ring {
        display: inline-block;
        position:"relative" ;      
        width: 80px;
        height: 80px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #695B76;;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #695B76 transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
  }