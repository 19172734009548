@import '../SCSS/cssVariable.scss';

.custPops {
  width: 75%;
  background-color: #ffccdc;
}

.tasksPops {
  width: 85%;
  background-color: $white-color;
  // transition: width 1s ease-in-out;

  .popBody {
    // display: grid;
    // grid-template-columns: 25em auto;
    height: 82vh;
   
  }
}

.popups {
  position: absolute;
  right: 0;
  // height: 95vh;
  height: 100vh;
  // margin-top: 21px;
  width: 100%;
  .addpopups {
    width: 75%;
    background-color: #e4e4e4;
    border-radius: 10px;
    overflow: hidden;
 
    .popBody::-webkit-scrollbar {
      background-color: $lightLogo1-color;
      width: 7px;
      border-radius: 30px;
    
    }
    
    
    .popBody::-webkit-scrollbar-thumb {
      background-color: $lightLogo1-color;
      border-radius: 30px;
    }
    
    *::-webkit-scrollbar:hover {
      width: 12px;
      transition: width 0.5s ease-in;
    
    }
    
    *:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 30px;
    }
    .popBody {
      max-height: 70vh;
      overflow-y: auto;
      background-color: #e5e5e5;
   
    }

  }
  .update-cust-popup{
    width: 80%;
    .ant-picker {
      height: 35px;
    }
   
  }
  .ant-upload-drag{
    &:hover{
      border-color: $logo2-color!important;
      svg{
        fill: $logo2-color!important;;
      }
    }
  }
  .ant-upload-drag-icon{
    margin: 0;
    svg{
      width: 50px;
      height: 50px;
      fill: $primary-color;
    }
  }
  .custom-file-info{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 14px;
    background-color: $white-color;
    padding: 5px 10px;
    border-radius: 6px;
    justify-content: space-between;
   .custom-img-thumb{
    width: 30px;
    height: 30px;
    // border: 1px solid #d3d3d3;
    // border-radius: 6px;
    overflow: hidden;
    .upload-spinner{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img{
      width: 100%;
    }
   }
   .ant-upload-icon{
    display: none;
   }
   .custom-icon{
      display: flex;
      cursor: pointer;
     svg{
      width: 17px;
      height: 17px;
      fill: $primary-color;
     }
     &:hover{
      svg{
        fill: $logo2-color;
      }
     }
    
    }
    .ant-upload-list-item{
      padding:15px 10px;
      border-radius: 6px;
      // height: 40px;
      margin: 0;
      flex: 1;
      .ant-upload-list-item-actions{
        display: none;
      }
      
    }
  }
  .customer-doc-popup{
    width: 50%;
   }
  .delete-warn{
    // width: 40% !important;
    .popBody {
        p {
          margin: 0.5rem 0;
          text-align: center;
        }
      }
    .yes-button{
      background-color: $primary-color;
      &:hover {
          background-color: #e99d22;
          color: #2c0036;
        }
    }
   }
  .pophead {
    padding: 0px 15px;
    width: 100%;
    background-color: $logo1-color;
    border-bottom: 1px solid $primary-color;
    font-size: $mainFntSize;
    color: #fff;

    .myIcon {
      @include icons();
      color: $white-color;
    }

  }

  .popBody::-webkit-scrollbar{
    background-color:$lightLogo1-color;
    width: 10px;
    border-radius: 30px;
    
}

.popBody::-webkit-scrollbar-thumb{
    background-color:$lightLogo1-color;
    border-radius: 30px;
}
*::-webkit-scrollbar:hover {
    width: 10px;
    transition: width 0.5s ease-in;
    
  }
*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

  .popBody {
    width: 100%;
    overflow-y: auto;

    .popcol1:nth-child(2) {
      overflow: hidden;
    }

    .popcol1::-webkit-scrollbar {
      background-color: $lightLogo1-color;
      width: 7px;
      border-radius: 30px;

    }

    .popcol1::-webkit-scrollbar-thumb {
      background-color: $lightLogo1-color;
      border-radius: 30px;
    }

    *::-webkit-scrollbar:hover {
      width: 12px;
      transition: width 0.5s ease-in;

    }

    *:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 30px;
    }

    .popcol1 {
      overflow-y: scroll;
      border-right: 2px solid $primary-color;

      .popsrightBorder {

        .popboxshadow {
          max-height: 63vh;
          min-height: 66vh;
          overflow: hidden;

          .extraBox::-webkit-scrollbar {
            background-color: $lightLogo1-color;
            width: 10px;
            border-radius: 30px;

          }

          .extraBox::-webkit-scrollbar-thumb {
            background-color: $lightLogo1-color;
            border-radius: 30px;
          }

          *::-webkit-scrollbar:hover {
            width: 10px;
            transition: width 0.5s ease-in;

          }

          *:hover::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.15);
            border-radius: 30px;
          }

          .extraBox {
            height: 60vh;
            overflow-y: scroll;
            padding-left: 5px;
          }

          .BtmLine {
            border-bottom: 2px solid $primary-color;
            background-color: $light-color3;

            .title {
              font-size: $mainFntSize;
              padding-right: 10px;
              margin-right: 10px;
              border-right: 1px solid $light-color;
            }

            .titleSideBotton {
              padding: 5px 10px;
            }
          }

        }
      }

      .popsrightBorder:nth-child(1) {
        border-right: 1px solid $primary-color;
      }
    }

    .did-floating-label-content {
      position: relative;
      margin-bottom: 20px;
    }

    .did-floating-label {
      color: #1e4c82;
      font-size: 13px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 15px;
      top: 11px;
      padding: 0 5px;
      background: #fff;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    .did-floating-input,
    .did-floating-select {
      font-size: 12px;
      display: block;
      width: 100%;
      height: 36px;
      padding: 0 20px;
      background: #fff;
      color: #323840;
      border: 1px solid #3D85D8;
      border-radius: 4px;
      box-sizing: border-box;

      &:focus {
        outline: none;

        ~.did-floating-label {
          top: -8px;
          font-size: 13px;
        }

        ~.did-floating-label {
          top: -8px;
          font-size: 13px;
        }
      }
    }

    select.did-floating-select {

      appearance: inherit;
    }


    .did-floating-input:not(:placeholder-shown)~.did-floating-label {
      top: -8px;
      font-size: 13px;
    }

    .did-floating-select:not([value="1"]):valid~.did-floating-label {
      top: -8px;
      font-size: 13px;
    }

    .did-floating-select:not([multiple]):not([size]) {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
      background-position: right 15px top 50%;
      background-repeat: no-repeat;
    }

    .did-error-input {

      .did-floating-input,
      .did-floating-select {
        border: 2px solid #9d3b3b;
        color: #9d3b3b;
      }

      .did-floating-label {
        font-weight: 600;
        color: #9d3b3b;
      }

    }

    .input-group {
      display: flex;

      .did-floating-input {
        border-radius: 0 4px 4px 0;
        border-left: 0;
        padding-left: 0;
      }
    }

    .input-group-append {
      display: flex;
      align-items: center;
      /*   margin-left:-1px; */
    }

    .input-group-text {
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 34px;
      color: #323840;
      padding: 0 5px 0 20px;
      font-size: 12px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid #3D85D8;
      border-radius: 4px 0 0 4px;
      border-right: none;
    }
  }

  .popfoot {
    background-color: $white-color;
    // box-shadow: 0px -4px 4px rgba($color: #000000, $alpha: 0.25);
    border-top: 1px solid $primary-color;
    z-index: 99;

    .cclBtn {
      background-color: $light-color;
      color: $primary-color;
    }
  }

  textarea {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 2px solid $primary-color;
    outline: none;
    transition: 0.3s;
    font-size: 14px;
    color: #000;
    margin-top: 10px;
  }

  .custInput {
    padding: 10px 0px;

    label {
      padding-left: 5px;

    }

    input {
      font-size: 20px;
    }

  }

  .bgColors {
    label {
      background-color: #FFCCDC;
    }
  }

  select {
    height: 100px;
    position: relative;
    overflow-x: scroll;
  }

  .popSubTitle {
    background-color: $white-color;
    border-bottom: 1px solid $primary-color;

    .popSubBtn {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .custTable {

    table {
      width: 100%;

      th {
        @include tableth();
      }

      td {
        @include tabletd();
      }



      tr:nth-child(even) {
        background-color: $white-color;
      }

      tr:nth-child(odd) {
        background-color: $light-color;
      }

      .clrStatus {
        width: 20px;
        height: 20px;
        border-radius: 30px;
        box-shadow: $insetShadow;
      }

      .grpofBtn {
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        height: 100%;

        .wblink {
          background-color: $primary-color;
          color: $white-color;
          font-size: $smallFntSize;
        }

        .wblink:hover {
          background-color: $logo2-color !important;
          color: $primary-color;
        }

        .edit {
          @include tableIcon(#fff500);
        }

        .delete {
          @include tableIcon(#FF6161);
        }
      }
    }
  }

}




.addCust {
  width: 33.33%;
  margin-bottom: 15px;
}


.form-floating {
  @include InputFields();
}

.quill{
  border-radius: 10px;
  overflow: hidden;
  height: 200px;
  border: 1px solid #c2c2c2;
  margin-bottom: 20px;
}

.myDatePicker{
  border: 2px solid white !important;
  width: 100%;
  padding: 0!important;
}
.myDatePicker:hover{
  border: 2px solid white !important;
}
.myDatePicker:focus{
  border: 2px solid white !important;
}

.myInputBox{
  // padding: 0.2rem 1rem;
  list-style: 1.25;
  border: 2px solid #ccabcd;
  border-radius: 5px;
  background-color: $white-color;
  font-size: 12px;
  font-family:$commonfntFmly ;
  color: $primary-color;
  padding: 6px 12px;
  .react-datepicker-wrapper{
    width: 100% !important;
    border: 2px solid white !important;
    .react-datepicker-ignore-onclickoutside:focus{
      border: 2px solid white !important;
    }
    .react-datepicker-ignore-onclickoutside:active{
      border: 2px solid white !important;
    }
    input{
      border: 2px solid $white-color !important;
      width: 100%;
    }
    input:focus{
      border: 2px solid $white-color !important;
      width: 100%;
    }
    input:active{
      border: 2px solid $white-color !important;
      width: 100%;
    }
  }
  .react-datepicker-popper{
    z-index: 999 !important;
  }
  label{
    font-family:$commonfntFmly ;
}
}
//  passwod
.add-pass-modal{
  .user-form-wrap{
    width: 50%;
  }
}
@media screen and (max-width:1024px){
  .popups {
    .addpopups, .update-cust-popup{
      width: 95%;
    }
  }
}
@media screen and (max-width:992px) {
  .addpopups{
    width: 90%!important;
    .popBody{
      .addCust {
        width: 100%!important;
        padding-right: 0!important;
        flex-wrap: wrap;
        .d-flex{
          flex-direction: column;
          gap: 15px;
        }
        .w-50{
          width: 100%!important;
          padding-right: 0!important;
        }
      }
      .text-area-wrap{
        width: 100%!important;
        padding-right: 0!important;
     
      }
    }
  }
}
.textarea-label{
  .form-control:not(:placeholder-shown)~label{
      width: 116%;
    }
 label{
  background: white;
  height: 13px !important;
  width: 99%;
  position: absolute;
  display: block;
  top: 9px!important;
  border-radius: 6px;
  left: 2px!important;
  padding: 4px 12px 26px!important;

 }
} 
//Audit trail

.audit-trail-popup{
  .addpopups{
    width: 40%;
  }
  .audit-container{
    .audit-row{
      background-color: $white-color;
      padding:14px 10px;
      border-radius: 6px;
      box-shadow: 0px 3px 4px 0px rgb(239 208 239);
      margin-bottom: 10px;
      border: 1px solid #c5c5c5;
      p{
        margin: 0;
        .status{
          color: red;
        }
      }
      span{
        font-weight: bold;
      }
    }
  }
}
.call-log-popup{
  .addpopups{
    width: 80%;
  }
  .ant-pagination {
    background: white;
    margin: 0;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    margin: 0!important;
  }
}
.start-call-wrap{
  .start-call{
    background-color: green!important;
    cursor: pointer;
   
    &:hover{
      color: $white-color!important;
    }
  }
  p{
    color: red;
    font-size: 13px;
    margin-top: 5px;
  }
  button[disabled]{
    background-color: #999!important;
    cursor: auto;
  }
}


.call-btn{
  display: flex!important;
  align-items: center;
  cursor: pointer;
  svg{
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}
.end-call{
  background-color: red!important;
  padding:6px 8px!important;
  font-size: 12px!important;
  &:hover{
    color: $white-color!important;
  }
  svg{
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}
.delete-contact{
  .instruction{
    font-family: $RFntFmly;
    text-align: left!important;
    margin-bottom: 20px!important;
    font-weight: 600;

    &.sub{
      font-weight: 400;
    }
  }
}
.offcanvas .btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e")
}
.centerpopups{
  .delete-options{
    .radio-btn{
      display: flex;
      align-items: flex-start;
      gap: 6px;
      margin-bottom: 10px;
      input{
        margin-top: 4px;
      }
      label{
        svg{
          margin-left: 5px;
          fill: #999;
        }
      
      }
    }
    .sub-text{
      font-size: 12px;
      margin: 0 0 10px 0!important;
      color: #747474;
      text-align: left!important;
    }
  }
}