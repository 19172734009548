@import '../SCSS/cssVariable.scss';


.Row1Col3{
    display: grid;
  column-gap: 30px;
  grid-template-columns: auto 25% 35%;
}

.Row2Col1{
  display: grid;
  row-gap: 30px;
  column-gap: 30px;
  .allCount{
    font-size: 2.4vw;
    font-family: $capFntFmly;
    line-height: 0.8;
    color: $primary-color;
  }
  .countTitle{
    font-size: 24px;
    font-family: $capFntFmly;
    color:$logo1-color;
    line-height: 0.8;
  }
}

.innerHole{
  // box-shadow: inset 2px 2px 5px rgba($color: #000000, $alpha: 0.1);
 @include InnerShadow();
  border-radius: 10px;
  // height: 100%;
  overflow: hidden;
  .noti-user-wrap{
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    padding: 10px;
    .user-profile{
      min-width: 40px;
      height: 40px;
      background-color: orange;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
    }
  }
  .heading{
    font-family: $commonfntFmly;
    // font-size: $mainFntSize;
    font-size: 20px;
  }
  .overSizeFlow{
    height: 81vh;
    overflow-y: auto;
    font-family: $commonfntFmly;
    font-size: $commonFntSize;
    padding: 0rem 0.8rem ;
    .title{
      color: $primary-color;
      // border-bottom: 0.5px solid $primary-color;
    }
    .date{
      // color: $lightLogo1-color
      color: #999;
      font-weight: normal;
    }
    .notTab{
      margin-bottom: 1rem;
    }
  }

}

.Row2Col2{
  display: grid;
column-gap: 30px;
grid-template-columns: auto 45%;
margin-top: 25px;
  .Row2Hgt{
    height: 45vh;
    margin-top: 20px;
    .negheading{
      margin-top: -40px;
    }
    .heading{
      font-size: $mainFntSize;
      font-family: $commonfntFmly;
    }
    .TablePad{
      @include PadTable();
      margin-top: -30px;
 
      .tableView{
        overflow-y: auto;
        height: 50vh;
        .date{
          font-size: $smallFntSize;
          color: $logo1-color;
          font-family: $commonfntFmly;
        }
        p{
          font-family: $lightFntFmly;
          padding: 10px 0px;
          margin-bottom: 0px;
        }
        .pstend{
          border-top: 2px solid $lightLogo1-color;
          padding: 5px 0px;
          .icons{
            @include icons();
          }
          .rplyBtn{
            padding: 0px 5px 0px 10px ;
            .myicon{
              height: auto;
            }
          }
        }
        .Pstheading{
          font-size: $commonFntSize;
          color: $logo2-color;
          border-bottom: 1px solid $primary-color;
          span{
            color: $light-primary;
          }
        }
      }
      table{
        border-collapse: collapse;
  width: 100%;
      }
      .TableContent{
        th, td{
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;;
          }
      
      }
      th{
        border-bottom: 2px solid #000000 ;
  
      }
      tr:nth-child(even) {
        background-color: $light-color;
      }
      tr:hover{
        background-color: $light-color3;
      }
      th, td{
        padding-top: 5px;
        padding-bottom: 5px;
      }
      th:first-child, td:first-child{
        padding-left: 5px;
      }
      th:last-child, td:last-child{
        padding-right: 5px;
      }
      .brdrTpBt{
        background-color: $light-color3;
        border-bottom: 2px solid $lightLogo1-color;
        border-top: 2px solid $lightLogo1-color;
        position: sticky;
        top: 0;
      }
      th:last-child{
        text-align: center;
      }
      td{
        font-family: $lightFntFmly;
        .clrStatus{
          background-color: #FAF7FB;
          text-align: center;
          border-radius: 50px;
          padding: 2px;
        }
      }
    }
  }


}

.overSizeFlow::-webkit-scrollbar,
.tableView::-webkit-scrollbar {
    background-color:$lightLogo1-color;
    width: 7px;
    border-radius: 30px;
    
}

.tableView::-webkit-scrollbar-thumb, .overSizeFlow::-webkit-scrollbar-thumb {
    background-color:$lightLogo1-color;
    border-radius: 30px;
}
*::-webkit-scrollbar:hover {
    width: 12px;
    transition: width 0.5s ease-in;
    
  }
*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}
.list-wrap{
  img{
    width: 100px;
  }
}

.number-widget{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
  div{
    flex: 1;
  }
  .card-style{
    @include InnerShadow();
    border-radius: 10px;
    padding: 20px 10px;
    img{
      padding-right: 20px;
    }
  }
  .bgCover{
    flex: 1;
  }
  .allCount{
    font-size: 52px;
    font-family: $capFntFmly;
    line-height: 0.8;
    color: $primary-color;
    margin-bottom: 5px;
  }
  .countTitle{
    font-size: 24px;
    font-family: $capFntFmly;
    color:$logo1-color;
    line-height: 0.8;
  }
}
.dashboard-page{
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  // height: 93vh;
  overflow-y:auto ;
  overflow-x:hidden ;
  padding-bottom: 10px;
 .row{
  height: 100%;
 }
  .post-wrap{
    border: 1px solid #b9b9b9;
    border-radius: 10px;
  }
  .right-section{
    flex: 1;
  }
  .left-section{
    flex: .6;
    width: 100%;
    .TablePad{
      @include InnerShadow();
      border-radius: 10px;
    }
    .innerHole{
      width: 100%;
    }
  }
 
  .dashboard-hot-post{
    .heading {
      font-size: 20px;
    }
    .hot-post{
      margin-top: 10px;
      .media-post{
        margin-bottom: 10px;
        img{
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .header{
        display: flex;
        gap: 14px;
        .date{
          color: #999;
        }
        .user-profile{
          width: 40px;
          height: 40px;
          border-radius: 100px;
          background-color: orange;
          display: flex;
            align-items: center;
            justify-content: center;
       
        }
      }
      
    }
   
  }
  .my-carousel-container {
    position: relative;
    height: 250px;
    .widget-card{
      // min-height: 190px;
      max-height: 190px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px; /* Width of the vertical scrollbar */
        height: 10px; /* Height of the horizontal scrollbar */
      }
    }
  }
  .no-data{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prev-btn,
  .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }
  
  .prev-btn {
    left: 10px;
  }
  
  .next-btn {
    right: 10px;
  }
  
  .tasktable-widget{
    table{
      min-width: auto;
    }
    .heading{
      font-size: 20px;
    }
    .TablePad{
     @include InnerShadow();
      border-radius: 10px;
      padding: 10px;
      
      td{
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}
.tasktable-widget{
  .TablePad {
    td{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
    }
    th{
      max-width: 30px;
      width: 30px;
    }
    .tableView{
      table{
       table-layout: unset!important;
       tr:nth-child(even) {
        background-color: $light-color;
    }
      }
     }
  }
  }
  // New Dashboard css here

  .widget-container{
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 8px -2px rgba(87, 87, 87, 0.75);
    .header{
      background-color: $logo1-color;
      .header-content{
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 10px;
        h4{
          font-size: 17px;
          color: #fff;
          margin: 0;
        }
        svg{
          fill: #fff;
          width: 20px;
          height: 20px;
        }
      }
    }
    .inner-widget{
      padding: 10px;
      .file-doc{
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #bdbdbd;
        margin-top: 5px;
        padding: 8px 5px;
        border-radius: 6px;
        background-color: #fff;
        img{
          width: 18px;
          height: 18px;
        }
        a{
          color: #2c0036;
          word-wrap: break-word;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-decoration: none;
          font-size: 12px;
        }
        span{
          color: blue;
          font-size: 12px;
          width: 80px;
          cursor: pointer;
        }
      }
      .post-title{
        font-size: 14px!important;
        font-family: $commonfntFmly;
      }
      .widget-card{
        padding: 8px;
        border-radius: 8px;
        box-shadow: 0px 0px 8px -2px rgba(87, 87, 87, 0.75);
        background-color: #fff;
        .mention{
          color: blue;
        }
        p{
          margin: 0;
        }
        .user-info{
           display: flex;
           gap: 8px;
           border-bottom: 1px solid #eaeaea;
           padding-bottom: 8px;
           margin-bottom: 5px;
           .user-profile{
              width: 34px;
              height: 34px;
              border-radius: 100px;
              background-color: #FF6C1A;
              display: flex;
              align-items: center;
              justify-content: center;
             
           }
           .user-name-category{
            flex: 1;
            p{
              font-size: 11px;
            }
            h5{
              font-size: 14px;
              margin: 0;
              color: $logo1-color;
           }
           }
          
          .post-time{
            flex: 1;
            font-size: 11px;
          }
        }
        .hot-post-content{
          align-items: flex-end;
          img{
            width: 70px;
            margin-right: 10px;
          }
        }
        .post-content{
          display: flex;
          // align-items: center;
          // justify-content: space-between;
          // padding-left: 42px;
          margin-top: 0!important;
          margin-bottom: 5px;
          flex-direction: column;
          .post-title{
            padding: 3px 0;
          }
          .description{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          p{
            font-size: 12px;
          }
        }
        .post-image{
          height: 80px;
          width: auto;
          border-radius: 10px;
          overflow: hidden;
          a{
            font-size: 12px;
          }
          span{
            font-size: 12px;
            margin-left: 10px;
          }
         img{
           width: 100%;
           height: 100%;
           object-fit: contain;
         }
         
        }
      }
      .reply-btn{
          padding: 4px 14px;
          width: 80px;
          svg{
            width: 18px;
            height: 18px;
          }
        }
    }
  }
  .no-data{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width: 120px;
      margin-bottom: 10px;
    }
    p{
      font-size: 14px;
    }
  }
  .recent-post-widget {
    .carousel-indicators{
      display: none;
    }
    .header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;
      svg{
        fill: #fff;
        width: 18px;
        height: 18px;
        cursor: pointer;
        &:hover{
          fill: $logo2-color;
        }
      }
    }
    
    .carousel-control-next{
      background: #fff;
      opacity: 1;
      height: 50px;
      top: 72px;
      width: 40px;
      border-radius: 7px;
      right: -6px;
      border: 2px solid $logo1-color;
      transition: ease-in-out .1s;
      svg{
        width: 24px;
        height: 24px;
        fill:$logo1-color
      }
      &:hover{
        border: 2px solid $logo2-color;
        svg{
          fill:$logo2-color
        }
      }
    }
    .carousel-control-prev{
      @extend .carousel-control-next;
      left: -6px;
    }
    .slide-wrap{
      display: flex;
      overflow: auto;
      gap: 15px;
      background-color: #F9F0FF;
      padding: 16px;
      .widget-card{
        // width: 480px;
        flex: 1;
      }
    }
    .bg-post-color{
      background-color: #fff;
    }
  }
  .taskWidget-wrap {
    .ant-pagination{
      display: none;
    }
    .billpage {
      th{
        padding:10px!important;
      }
      .showContent{
        background-color: #fff;
        height: 100%;
        z-index: 9;
        
      }
      .task-row{
        display: flex;
        border-bottom: 1px solid #CFCFCF;
        .taskdue{
          color:red;
          font-size: 24px;
          margin-right: 4px;
          font-family: $commonfntFmly;
        }
        &:last-child{
          border: none;
        }
        p{
          flex: 2;
          padding:6px 10px;
          margin: 0;
          display: flex;
          align-items: center;
          &:nth-child(2){
            flex: 1;
          }

        }
      }
      .card{
        max-height: calc(98vh - 398px);
        height: calc(98vh - 398px);
        overflow: auto;
        box-shadow: 0px 0px 8px -2px rgba(87, 87, 87, 0.75);
        background-color: #fff;
        border-top-left-radius: 0;
      
      }
    }
    .billpage {
      padding-top: 20px;
      padding-bottom: 0;
      table {
        min-width: auto;
        .ant-table-row td{
          padding: 5px 10px!important;
        }
      }
      .billTab {
        .box {
          text-decoration: none;
        }
        .active{
          .headTitle{
            color:#fff;
          text-decoration: none;
          }
          &:nth-child(1)::after, &:nth-child(2)::after{
            border-bottom: 5vh solid $logo1-color;
          }
        }
        .box:nth-child(1), .box:nth-child(2){
          filter: drop-shadow(-1px 0px 3px rgba(0, 0, 0, 0.3));
        }
      }
      .ant-table-body{
        min-height: auto;
        height: auto;
      }
    
    }
  }
  .recent-notes-widget{
    .inner-widget-wrap{
      height: calc(100vh - 144px);
      overflow: auto;
    }
    .inner-widget{
      // height: calc(100vh - 140px);
      overflow: auto;
      padding: 5px 10px;
    }
    .post-content{
      flex-direction: row!important;
      justify-content: space-between;
      p{
        font-size: 12px!important;
        word-break: break-all;
      }
      button{
        font-size: 11px;
      }
    }
  }

@media screen and (max-width: 1180px) {
 .dashboard-page{
  .taskWidget-wrap {
    margin-bottom: 10px;
  }
  .left-section{
    flex:100%;
  }
  .left-section{
    padding-bottom: 27px;
  }
 }
}
@media screen and (max-width:1024px){
  .taskWidget-wrap{
    .billpage .card{
      height: calc(100vh - 372px);
      max-height: calc(100vh - 372px);
    }
  }
  .recent-notes-widget {
    .inner-widget-wrap{
      height: calc(100vh - 131px);
    }
  }
}
@media screen and (max-width:576px){
  .widget-container {
    .header .header-content{
       padding: 6px;
        h4{
          font-size: 15px;
        }
    }
  }
}
@media screen and (max-width: 480px) {
  .dashboard-page{
   
    .tasktable-widget {
      .TablePad {
      
        .tableView{
         table{
          table-layout: unset!important;
         }
        }
      }
    }
    .taskWidget-wrap {
      .billpage {
        .ant-table-body{
          height: calc(94vh - 541px) !important;
        }
        .card{
        height: calc(84vh - 398px);
        }
      }
    }
    .recent-notes-widget {
      .inner-widget-wrap{
        height: calc(75vh - 144px);
      }
    }
  }
  .number-widget {
    .card-style {
      .allCount{
        font-size: 46px;
      }
      img{
        width: 85px;
      }
    }
  }
  .innerHole .overSizeFlow{
    height: 40vh;
  }
  .widget-container .inner-widget .widget-card .user-info .user-name-category h5{
    font-size: 13px;
  }
 }
