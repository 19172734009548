@import './components/SCSS/cssVariable.scss';
@import '../src/components//SCSS/file-upload.scss';
@mixin tableIcon($bgColor: '#000000') {
  background-color: $bgColor;
  width: 25px;
  height: 25px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $RFntFmly;
}

.App {
  display: flex;
  position: relative;
  height: 100vh;
  overflow: hidden;
  font-size: $commonFntSize;
  width: 100vw;
}
.ant-table-body{
  min-height: 350px;
  height: calc(-240px + 100vh);
  overflow: auto!important;
}
.billpage{
  .ant-table-body{
    height: calc(-320px + 100vh);
  }
}
.ant-picker-dropdown{
  z-index: 9999!important;
}
.see-more-btn{
  color: blue;
  font-size: 12px;
  margin-top: 3px;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.cancel-button{
  margin-top: 20px;
  color: #000;
  text-decoration: none;
  align-items: center;
  &:hover{
    color: $logo2-color;
  }
}
.userpage{
  .ant-table-body{
    height: calc(-230px + 100vh);
  }
}
.call-log-popup{
  .popBody {
    max-height: max-content!important;
  }
  .ant-table-body{
    height: 300px;
    min-height:200px;
    max-height:500px;
    overflow: auto;
  }
}
.notificationpage-wrap{
  .ant-table-body{
    height: calc(-240px + 100vh);
  }
}
.customer-body{
  .ant-table-body{
    height: 200px;
    min-height: 212px;
  }
}
.assign-user-container{
  .ant-table-body{
    height: 200px;
    min-height: 212px;
  }
  .user-name-wrap{
    .ant-table-header{
      z-index: 1;
    }
  }
}
.PageContent {
  width: 100%;
  margin-top: 70px;
  overflow: auto;
}
textarea{
  font-size: 14px;
  color: #000;
  min-height: 70px!important;
}
.ant-table-thead{
  th{
    font-family: $commonfntFmly;
    padding: 12px 16px !important;
    span{
      font-family: $commonfntFmly;
    }
  }
}
.TBtn {
  display: flex;
  align-items: center;
  padding: 5px;
  box-shadow: inset -0.5px -0.5px 1px rgba($color: #000000, $alpha: 0.25);
  border-radius: 3px;
  background-color: $white-color;
}

.TBtn:hover {
  box-shadow: inset 0.5px 0.5px 1px rgba($color: #000000, $alpha: 0.25);
  background-color: $logo2-color !important;
  color: $white-color;
}

.TBtn.active-btn {
  box-shadow: inset 0.5px 0.5px 1px rgba($color: #000000, $alpha: 0.25);
  background-color: $logo2-color !important;
  color: $white-color;
}

tr {
  border-left: 1px solid $light-color;
  border-right: 1px solid $light-color;
}
.form-control {
  border: none;
  border-bottom: 2px solid $primary-color;
  border-radius: 0px;
  background-color: transparent;
  
}

.form-control:focus {
  border: none;
  border-bottom: 1ps solid $primary-color;
  border-radius: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;


}

.quickpopup{
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  background: rgba($color: #000000, $alpha: 0.7);
  .quickpopupDesign{
    background-color: $white-color;
    min-width: 30%;
    text-align: center;
    .OkBtn{
      width: 50px;
      height: 50px;
      background-color: $primary-color;
      color: $white-color;
      font-family: $capFntFmly;
      font-size: 32px;
    }
  }
}


.App::-webkit-scrollbar{
  background-color:$lightLogo1-color;
  width: 10px;
  height: 5px;
  border-radius: 30px;
  
}

.App::-webkit-scrollbar-thumb {
  background-color:$lightLogo1-color;
  border-radius: 30px;
}
*::-webkit-scrollbar:hover {
  width: 10px;
  transition: width 0.5s ease-in;
  
}
*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.ant-table-body::-webkit-scrollbar {
  width: 10px; /* Width of the vertical scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: #bbb; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Radius of the scrollbar thumb */
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #999; /* Color of the scrollbar thumb on hover */
}

.ant-table-body::-webkit-scrollbar-track {
  /* Style for the scrollbar track */
  background: #f4f4f4; /* Color of the scrollbar track */
}
.myNewSpin{
  font-size: 5em;
  color: $primary-color;
}

.ant-table-filter-dropdown{
  .ant-space-vertical{
    max-width: 300px;
    max-height: 320px;
    overflow: auto;
  }
  .input-tip{
    margin-left: 4px;
    font-size: 12px;
    color: rgba(0, 0, 0, .5);
  }
}
.ant-table-filter-dropdown-btns{
  button{
    &:nth-child(2){
      background-color: $primary-color;
      &:hover{
        background-color: $logo2-color!important;
      }
    }
  }
}
.ant-btn-primary{
  background-color: $primary-color!important;
  &:hover{
    background-color: $logo2-color!important;
  }
  &:disabled{
    color: #fff;
  }
}
.more-option-wrap{
  .dropdown-menu{
    position: fixed!important;
    z-index: 999;
  }
}
table{

  td{
    word-wrap: break-word!important;
    text-wrap: wrap!important;
    // width: 150px!important;
    // max-width: 100%!important;
    // min-width: 100px!important;
  }
  tr:nth-child(even) {
    background-color: $light-color;
}
tr{
  vertical-align: baseline;
  &:hover{
    background-color: #E4D0E4!important;
    td{
      background-color: #E4D0E4!important;
    }
  }

}
.ant-table-placeholder{
  &:hover{
    background-color: #FFF!important;
    td{
      background-color: #FFF!important;
    }
  }
}
}
.popups .pophead .myIcon{
  display: flex;
}
.ant-table-column-sort{
  background-color: transparent!important;
}
.PageContent{
  table{
    th{
      font-size: 12px;
    }
    .ant-table-row td{
      font-size: 12px;
    padding: 8px 16px!important;
    font-family:$RFntFmly!important ;
   div,p,span{
    font-family:$RFntFmly!important ;
   }
    }
    
  }
}.ant-table-wrapper .ant-table-filter-trigger.active, 
 .ant-table-column-sorter-up.active,
 .ant-table-column-sorter-down.active{
  color: $logo2-color!important;
}
.ant-picker-active-bar {
  background:$primary-color!important;
}
.ant-picker-focused.ant-picker {
  border-color: $primary-color;
}
.ant-picker {
  &:hover,
  &:active,
  &:focus {
    border-color: $primary-color!important;
  }
}
.ant-btn-link{
  color: $logo2-color;
}
.ant-picker{
  border: 2px solid #CCABCD;
  border-radius: 6px;

  &:focus{
      outline: none;
      border: 2px solid $primary-color;
      box-shadow: none;
  }
  input{
      border: none;
      font-size: 14px!important;
      &:focus{
          border: none;
      }
  }
 
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.accordion{
  .ant-table-sticky-scroll{
    display: none;
  }
  .accordion-item{
    .accordion-button {
      border-bottom: 3px solid #a563a6;
      // box-shadow: 2px 3px 2px -1px rgba(161,161,161,1);
    }
    .accordion-body{
      .credit-card-wrapper{
        .ant-table-body{
          min-height: 80px;
        }
      }
    }
  }

}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
.PageContent{
  table{
    width: 100%;
    min-width: 800px;
    overflow: auto;
    
  }
  .ant-table-footer{
    position: absolute;
    background-color: transparent;
}
}
.form-floating{
  .phone-select{
    width: 150px;
  }
  input,select{
    font-size: 14px;
  }
}
.user-form-wrap{
  padding: 0!important;
}
.notificationpage-wrap{
  .ant-table-cell{
    p{
      display: flex;
      flex-direction: column;
    }
    img{
      width: 90px;
      height: 50px;
      object-fit: contain;
      border: 1px solid #dedede;
      border-radius: 4px;
    }
    
  }
}

.ant-upload-wrapper{
  .ant-upload {
    height: auto;
  }
}
.ant-upload-list.ant-upload-list-text{
  display: flex;
  flex-direction: column;
}
#button-tooltip{
  font-size: 11px;
  --bs-tooltip-max-width: 300px
}
.user-list-wrap{
  height: 150px;
  overflow: auto;
  label{
    font-size: 12px;
  }
  input[type="checkbox"]{
    width: 15px;
  }
}
.inner-menu-content{
  .myInputBox{
    input{
      margin-bottom: 0;
    }
  }
.search-box{
  width: auto;
}
}
.ant-upload-list-item-thumbnail{
  border-radius: 6px;
}
.high-priority-task{
  background-color: #fecede!important;
}
.custom-file-item {
  .ant-upload-list-item-name{
    text-decoration: none;
    font-family: $RFntFmly;
  }
  .upload-file-name{
    a{
      width: 190px;
      font-size: 12px;
      color: #000;
      text-decoration: none;
      margin-left: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.file-error{
  .custom-file-info{
    border: 1px solid red;
  }
 
  .file-error-text{
    color: red!important;
    font-family: $RFntFmly;
  }
  .ant-upload-list-item-name{
    font-family: $RFntFmly;
  }
}
.redirect-icon {
  height: 1.3em;
  width: 2em;
  cursor: pointer;
}
.redirect-cusor {
  cursor: pointer;
}

.ant-space-item{
  width: 100%;
}
.filter-yellow-color {
  svg{
    fill: $logo2-color !important;
  }
}
// TimeRage
.start-end-time{
  display: flex;
  flex-wrap: wrap;
  .time{
    flex:1
  }
}
.accordion-body{
  .credit-card-wrapper{
    .TBtn{
      width: 23px;
    }
    .header{
      background-color: $logo1-color;
      color: $white-color;
      font-size: 14px;
      align-items: center;
      border-radius: 8px 8px 0 0;
    }
  }
}
.ant-table-row-selected{
  .ant-table-cell{
    background-color: #e6f4ff!important;
  }
}
// custom select box
.custom-select-box-wrap{
  display: flex;
    align-items: center;
    background: white;
    padding-right: 12px;
    border-radius: 4px;
    border: 2px solid #CCABCD;
    gap: 10px;
  .custom-select{
    flex: 1;
    select{
      // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAz0lEQVR4Xu2SwQkAIAhEX//9y+IISI6kjCuE6N79SwC43WJDXJHeLBYeCBkFR9aIIGzfC2QJyvC7JF4LjwnEz5FtBc/JE4Iy3Kz8kXRBydYlRfXfVga6v1goDuk1TakRfEIEHQyqF3Z1xUUQNRvKVHUqYwWlVFhq+uB1soqX4YiEQBhZGptblABAAAAAElFTkSuQmCC');
      // padding-right: 70px;
      border: none;
      &:focus{
        border: none;
      }
    }
  
  }
  .icon{
    // position: absolute;
    // right: 15px;
    // top: 21px;
    // display: flex;
    // align-items: center;
    // gap: 20px;
    .icon-style{
        width: 16px;
        height: 16px;
        z-index: 1;
        cursor: pointer;
        &:hover{
          fill: $logo2-color;
        }
    }
    .arrow-icon{
      @extend .icon-style;
      right: 50px;
      z-index: 0;
      cursor:auto;
    }
  }
}
.bg-select-color{
  background-color: #e9ecef;
}
.ant-table-sticky-scroll{
  width: 0;
  height: 0!important;
}
.align-wrap{
  display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.copy-btn{
  display: flex!important;
  align-items: center;
  gap: 8px;
  margin-left: 10px;
  span{
    font-size: 12px;
  }
}
.inner-task-table{
  table{
    table-layout: auto!important;
    td{
      font-size: 12px;
      padding: 5px 10px !important;
     
    }
    tr:first-child{
        td{
          padding: 0!important;
        }
      }
  }
}
.cursor-pointer{
  cursor: pointer;
}
.popups{
  .successfully-popup{
    width: auto;
    background-color: #fff;
    padding: 10px;
    .success-icon{
      font-size: 50px;
      color: green;
      margin-bottom: 20px;
    }
    .text-center{
      padding: 20px 60px 10px 60px;
    }
    .popfoot{
      border: none;
    }
  }
}

.tasks-rows{
  .ant-table-body{
    min-height: auto;
    height: auto;
    max-height: 200px;
    table{
      min-width: auto;
    }
  } 
}
.activeLog {
  @include tableIcon(#fff);
  width: max-content;
  // svg{
  //     width: 23px;
  //     height: 23px;
  // }
  &:hover{
      button{
        background-color: transparent;
          svg{
                  fill: #fff;
          }
      }
  }
 &:focus{
  background-color: transparent;
 }
  .search-box{
      margin:0 10px 10px 10px;
      font-size: 14px;
      padding: 8px;
      border-radius: 6px;
      border: 1px solid #d7d7d7;
     }
  .form-check{
  label{
      font-size: 12px;
  }
}
  .dropdown-toggle{
      background-color: transparent !important;
      padding: 0;
      border: none;
      &::after{
          display: none;
      }
      svg{
          fill: #000;
      }
      &:hover{
          svg{
              fill: $white-color;
          }
      }
     
  }
  .btn-wrap{
      .assign-btn{
          background-color: $primary-color;
          border: none;
          outline: none;
          padding: 8px 10px;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
          &:hover{
              background-color:$logo2-color;
          }
      }    
  }
}
.header {
  div{
      svg{
          width: 24px;
          height: 24px;
      }
  }
}
.ant-picker-input, .ant-picker-range {
  .ant-picker-clear{
    opacity: 1;
  }
}
.ant-popover{
  // z-index: 1051 !important;
}

.centerpopups.hs-note-modal{
  z-index: 9999 !important;

}

.list-overflow{
  overflow-y: auto;
}

.search-option:hover {
  background-color: #d3d3d3 !important;
}

.document-viewer{
  .document-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 36px;
    
    span{
      font-size: 16px;
    }
  }

  #react-doc-viewer{
    height: calc(100vh - 40px);

    #proxy-renderer{
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
.ant-tooltip-inner{
  font-size: 12px;
}
.height-auto{
  display: flex;
  flex: 1;
  .form-floating{
  @extend .height-auto;
    input{
      height: auto;
    }
  }
}

.task-popup-assign {
  .ant-spin .ant-spin-dot {
    width: auto;
    height: auto;
  }
}

.state-loader-wrapper {
  position: relative;

  .state-loader {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-70%);
    width: 20px;
    height: 20px;
  }
}
.disabled-field{
  pointer-events: none;
  background-color: var(--bs-secondary-bg) !important;
  border: 2px solid #d1d1d1!important;
}
.form-floating {
  input[disabled]{
  border: 2px solid #d1d1d1!important;

  }
}
.disabled-label{
  pointer-events: none;
}
.small-text{
  font-size: 12px;
}
.form-floating>label{
  font-size: 14px;
}
.button[disabled]{
  background-color: #d1d1d1!important;
  pointer-events: none;
}
.multiselect-notfound{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectdropdown {
  .multiselect-notfound {
    height: 50px;
  }
}

.multiple-client-job-delete.action-btn{
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  margin-right: 10px;
  p{
    margin: 0;
    font-size: 14px;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .action{
    cursor: pointer;

    &:hover{
      color: #e99d22;
    }
  }
}
.view-report-page{
  .ant-tabs-nav-wrap{
    .ant-tabs-tab-active{
      .ant-tabs-tab-btn{
        color: $logo1-color;
        text-shadow: none;
      }
    }
    .ant-tabs-ink-bar{
      background-color: $logo1-color;

    }
    .ant-tabs-tab{
      &:hover{
        color: $logo1-color;
      }
    }
  }
}
@media screen and (max-width:1024px){
  .billpage{
    padding-top: 0!important;
    .ant-table-body{
      min-height:320px;
    }
  }
}
@media screen and (max-width:992px){
  .addpopups{
    .hours-time-wrap{
      width: 100%!important;
      margin-bottom: 10px;
    }
  }
  #button-tooltip{
    display: none;
  }
  .ant-tooltip{
    display: none;
  }
  .state-loader-wrapper {
    .state-loader{
      right: 35px;
    }
  }
}
.delete-modal{
  z-index: 99999 !important;
}
.delete-modal-contact {
  z-index: 999 !important;
}
.set-primary-modal{
  .message{
     font-weight: bold;
     text-align: left !important;
    }
  .message-weight-normal{
    font-weight: normal !important;
  }
  .delete-warn{
    width: 420px;
  }
}
:where(.css-dev-only-do-not-override-p7e5j5).ant-tooltip{
  z-index: 99999 !important;
}
.hubspot-section {
  .access-denied-message {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    svg{
      font-size: 40px;
      color: #ff4d4f;
    }

    p {
      font-size: 18px;
      width: 75%;
      text-align: center;
    }
  }

  &.hubspot-section-details {
    .access-denied-message {
      height: calc(100vh - 60px);
    }
  }
}

td.ant-table-cell.owner-column {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  word-break: keep-all !important;
}

.report-job-status{
  width: 33%;
  margin-right: 45px;

  .multiselect-wrap{
    padding: 12px;
  }
}

.full-page-loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;

  .ant-spin {
      .anticon {
          font-size: 50px;
          color: #2c0036;
      }
  }
}
.view-report-page {
  .view-table-wrapper {
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 80px);

    .ant-table-body{
      max-height: calc(100vh - 80px) !important;
    }
  }

    .date-range-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .selectBox-wrap {
        width: 20%;
        min-width: 254px;
  
        .form-select {
          &:focus {
            border-color: #212529;
            box-shadow: none;
          }
        }
      }
    }

  thead {
    th {
      background-color: #E4D0E4 !important;
    }
  }
}

@media screen and (max-width:772px){
  .hubspot-section-details{
    width: 100%;
    overflow: auto;
    .column{
      width: 1300px;
    }
  }
}
@media screen and (max-width:576px) {
  .PageContent .ant-table-footer{
    position: unset;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    padding-top: 5px;
  }
  .start-end-time{
    .time{
      flex:100%;
    }
  }
  .PageContent .ant-pagination {
    justify-content: center;
    margin: 4px 0!important;
  }
  .ant-picker-panel-container{
    // width: 94%!important;
    // margin: 0 auto!important;
    // position: absolute;
    bottom: -250px;
    .ant-picker-panels{
      flex-wrap: wrap!important;
    }
  }
  .not-found-wrap{
    margin-top: 30px;
  }

}
@media screen and (max-width:480px){
  .notificationpage-wrap{
    .header{
      flex-wrap: wrap;
      gap: 10px;
    }
    .ant-table-body{
      height: calc(-262px + 94vh);
    }
  }
  .ant-table-body{
    height: calc(-240px + 97vh);
  }
  .userpage{
    .ant-table-body{
      height: calc(-240px + 97vh);
    }
  }

  .popups .pophead{
    font-size: 14px;
  }
}

