@import '../SCSS/cssVariable.scss';

@mixin tableIcon($bgColor: '#000000') {
    background-color: $bgColor;
    width: 25px;
    height: 25px;
}

@include contentHearderBtn();
@include tableLoading();

.jobpage {

    @include tableScroll();

    .custTable {
        width: 100%;
        // height: 93%;
        // overflow-y: auto;

        .high-priority{
            background-color: rgb(254, 206, 222);
        }
    }

    .bottomBar {
        position: absolute;
        bottom: 0px;
        background-color: $white-color;
        width: 100%;

        .btmwidth {

            box-shadow: 0px -4px 4px rgba($color: #000000, $alpha: 0.25);

            .btmbtn {
                display: inline;
                width: fit-content;
            }

            .prevBtn {
                background-color: $light-color3;
                margin-right: 20px;
            }
        }
    }
.recurring-icon{
    fill: #337ab7;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    cursor: pointer;
    &:hover{
        fill: $logo2-color;
    }
}
    table {
        width: 100%;

        th {
            @include tableth();
        }

        td {
            @include tabletd();
            // white-space-collapse: collapse;
            text-wrap: wrap;
        }

        td:nth-child(1), th:nth-child(1) {
            // width: auto !important;
            white-space-collapse: collapse;
            text-wrap: wrap;
        }

        // td:nth-child(2), th:nth-child(2) {
        //     width: auto !important;
        // }

        // td:nth-child(3), th:nth-child(3) {
        //     width: auto!important;
        // }

        // td:nth-child(4), th:nth-child(4) {
        //     width: auto !important;
        //     white-space-collapse: collapse;
        //     text-wrap: wrap;
        // }

        // td:nth-child(5), th:nth-child(5) {
        //     width: 10% !important;
        // }

        tr:nth-child(even) {
            background-color: $light-color;
        }

        tr:hover {
            background-color: $light-color3;
        }

        tr {
            cursor: pointer;
        }

        .pass {
            @include tableIcon($white-color);
        }

        .activeLog {
            @include tableIcon(#fff);
            width: max-content;
        }
    }
    .ant-table-wrapper .ant-table-thead>tr>th:nth-child(1) {
        // width: 15%;
        white-space-collapse: collapse;
        text-wrap: wrap;
    }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(2) {
    //     width: 12%;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(3) {
    //     width: 15%;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(4) {
    //     width: auto;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(5) {
    //     width: auto;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(6) {
    //     width: auto;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(7) {
    //     width: auto;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(8) {
    //     width: auto;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(9) {
    //     width: auto;
    // }
    
    // .ant-table-wrapper .ant-table-thead>tr>th:nth-child(10) {
    //     width: 2%;
    // }

        .header-wrapper {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
    
            .selectBox-wrap {
                width: 60%;
                margin-right: 1rem;
    
                select {
                    border: 2px solid #CCABCD !important;
                    font-size: 13px;
                }
            }
        }
}



@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

// Task scss Here
.popups .activity-log {
    background-color: #fff;

    .tabs-wrap {
        .hide-content {
            display: none;
        }

        .tabs {
            display: flex;
            margin-top: 20px;
            gap: 10px;

            .tab {
                padding: 3px 8px;
                border-radius: 3px;
                background-color: rgb(205, 205, 205);
                cursor: pointer;

                span {
                    font-family: $RFntFmly;

                }
            }

            .active {
                background-color: #ECD5F1;
                color: #481951;
            }
        }
    }

    .text-editor-wrap {
        display: flex;
        gap: 10px;

        p {
            font-family: $RFntFmly;
            color: #717171;
        }
    }

    .initial-text {
        span {
            width: 36px;
            height: 36px;
            border-radius: 100px;
            background-color: orange;
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .comments-content {
        display: flex;
        gap: 10px;
        margin-top: 15px;
        align-items: center;

        p {
            margin: 0;
            font-size: 13px;
        }

        .user-details {
            display: flex;
            gap: 10px;

            .date-time {
                font-family: $RFntFmly;
                color: #717171;
            }
        }

        .comment-action {
            display: flex;
            gap: 15px;

            a {
                text-decoration: none;
                font-family: $RFntFmly;

                svg {
                    fill: #717171;

                    &:hover {
                        fill: red;
                    }
                }
            }
        }
    }

    .align-start {
        align-items: start;
    }

    .italic-font {
        font-style: italic;
    }

}

// table {
//     .ant-table-thead {
//         tr {
//             th::nth-child(1) {
//                 width: 30%;
//             }
//         }
//     }
// }
.job-form-popup{
    .addpopups{
        width:66%;
    }
}
@media screen and (max-width:992px){
    .jobpage {
        .header-wrapper{
            width: 80%;
        }
    }
}
@media screen and (max-width:480px){
    .jobpage {
        .header-wrapper{
            width: 100%;
            .selectBox-wrap{
                margin-left: 0!important;
            }
        }
    }
}

